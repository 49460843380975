import axios from "axios"

const defaultHeader = {
  "Content-Type": "application/json",
  // Other headers if needed
}

export const LOADING_STATUS = {
  NOT_STARTED: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3,
}

export function isApiLoaded(status) {
  return [
    LOADING_STATUS.LOADED,
    LOADING_STATUS.ERROR,
    LOADING_STATUS.NOT_STARTED,
  ].includes(status)
}

export function httpClient(baseURL, customHeaders) {
  const httpService = axios.create({
    baseURL,
    timeout: 600000,
    headers: { ...defaultHeader, ...customHeaders },
  })

  // Add request interceptor
  httpService.interceptors.request.use(
    (config) => {
      // You can add logic here, like attaching tokens or logging
      console.log("Request config:", config)
      return config
    },
    (error) => {
      // Handle request error
      console.error("Request error:", error)
      return Promise.reject(error)
    }
  )

  // Add response interceptor
  httpService.interceptors.response.use(
    (response) => {
      // Handle successful responses
      // console.log("Response:", response)
      return response.data // Returning only the data from the response
    },
    (error) => {
      // Handle response errors
      console.error("Response error:", error.response || error)
      const customError = {
        status: error.response?.status,
        message: error.response?.data?.message || error.message,
      }
      return Promise.reject(customError)
    }
  )

  return httpService
}
