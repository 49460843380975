export const META_INSIGHTS_TELEMETRY_BASE_URL =
  "https://metainsights-api.gleafink.com/metainsights"

const BASE_URL_ENV_MAP = {
  development: {
    metaInsightsTelemetry: `${META_INSIGHTS_TELEMETRY_BASE_URL}`,
  },
}

export function getBaseURLs(environment = "development") {
  return BASE_URL_ENV_MAP[environment || "development"]
}
