import { createContext, useContext } from "react"
import { useImmerReducer } from "use-immer"
import reducer from "./reducer"

const initialState = {
  lineageDepth: 2,
  lineageView: {
    columns: false,
  },
  treeData: {
    nodes: [],
    edges: [],
  },
  sidePanelData: null,
  sidePanelShow: false,
}

const Context = createContext(initialState)

// Hook to access context values
export const useValue = () => {
  return useContext(Context)
}

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState)
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}

export default ContextProvider
