import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Chip,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import editicon from "../../assets/images/pencil.svg";
import deleteicon from "../../assets/images/trash.svg";
import BreadcrumbMUI from "../../components/NewComponents/BreadcrumbMUI";
import "../../styles/ManageViews.styles.css";
import CreateViewModal from "./CreateViewModal";
import { NavBarAndLandingPageContext } from "../../NavBarContextAndLandingPageContext";
import { AuthContext } from "../../AuthContext";
import { toast, ToastContainer } from "react-toastify";
import UpdateViewModal from "./UpdateViewModal";
import DeleteViewModal from "./DeleteViewModal";

const ManageViews = () => {
  const { browseData, browseFetching, viewsData, fetchViews } = useContext(
    NavBarAndLandingPageContext
  );

  const { getToken, token, error } = useContext(AuthContext);

  const [BUList, setBUList] = useState([]);
  const [selectedBU, setSelectedBU] = useState([]);
  const [viewName, setViewName] = useState("");

  const [newViewModal, setNewViewModal] = useState(false);
  const [updateViewModal, setupdateViewModal] = useState({});
  const [deleteViewModal, setDeleteViewModal] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (browseData?.length !== 0) {
      browseData?.map((category) => {
        if (category.name === "Business Units") {
          setBUList(category.subCategories);
        }
      });
    }
  }, [browseData]);

  return (
    <>
      <BreadcrumbMUI last="Manage Views" />
      <div className="manageViewsContainer">
        <h3>Manage Views</h3>
        <Button
          className="addNewButton"
          variant="contained"
          size="small"
          onClick={() => setNewViewModal(true)}
        >
          Create View
        </Button>
        <TableContainer component={Paper}>
          {browseFetching ? (
            <Skeleton variant="rounded" width={"100%"} height={180} />
          ) : (
            <>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>View Name</TableCell>
                    <TableCell>Business Units</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {viewsData.map((view) => (
                    <TableRow
                      key={view.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {view.name}
                      </TableCell>
                      <TableCell>
                        {view.businessUnits.map((value) => (
                          <Chip key={value} label={value} sx={{mx:0.4}}/>
                        ))}
                      </TableCell>
                      <TableCell>
                        <img
                          src={editicon}
                          alt="Edit View"
                          className="actionIcons"
                          onClick={()=>{setupdateViewModal(view)}}
                        />
                        <img
                          src={deleteicon}
                          alt="Delete View"
                          className="actionIcons"
                          onClick={()=>setDeleteViewModal(view)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={viewsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      </div>
      {newViewModal && (
        <CreateViewModal
          open={newViewModal}
          setOpen={setNewViewModal}
          token={token}
          viewName={viewName}
          setViewName={setViewName}
          BUList={BUList}
          selectedBU={selectedBU}
          setSelectedBU={setSelectedBU}
          browseFetching={browseFetching}
          toast={toast}
          fetchViews={fetchViews}
        />
      )}
      {Object.keys(updateViewModal).length !== 0 && (
        <UpdateViewModal
          open={updateViewModal}
          setOpen={setupdateViewModal}
          token={token}
          BUList={BUList}
          browseFetching={browseFetching}
          toast={toast}
          fetchViews={fetchViews}
        />
      )}
      {Object.keys(deleteViewModal).length !== 0 && (
        <DeleteViewModal
          open={deleteViewModal}
          setOpen={setDeleteViewModal}
          token={token}
          toast={toast}
          fetchViews={fetchViews}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={1300}
        newestOnTop
        closeOnClick
        draggable
        pauseOnHover
        style={{ width: "450px", height: "250px" }}
      />
    </>
  );
};

export default ManageViews;
