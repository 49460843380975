import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/NotFound";
import SearchResults from "./pages/SearchResults";
import DatasetDetails from "./pages/DatasetDetails";
import Navbar from "./components/NewComponents/Navbar";
import { MsalProvider } from "@azure/msal-react";
import { NavBarAndLandingPageProvider } from "./NavBarContextAndLandingPageContext";
import { AuthProvider } from "./AuthContext";
import MyConnections from "./pages/MyConnections/MyConnections";
import NewDataSourceConnection from "./pages/MyConnections/NewDataSourceConnection";
import MetadataCrawl from "./pages/MyConnections/MetadataCrawl";
import ConnectionDetail from "./pages/MyConnections/ConnectionDetail";
import BulkView from "./pages/BulkView";
import TagsClassificationGlossary from "./pages/TagsClassificationGlossary";
import ContextProvider from "./context/ContextProvider";
import ManageProjects from "./pages/ManageProjects/ManageProjects";
import ContextProviderDCT from "./context/ContextProviderDCT";
import UserInsights from "./pages/UserInsights";
import AddProduct from "./components/NewComponents/ReactTables/AddProduct/AddProduct";
import ManageMetadataCrawls from "./pages/manageMetadataCrawls/ManageMetadataCrawls";
import AddNewMetaDataCrawlData from "./components/NewComponents/MetaDataCrawl/addNewData/AddNewMetaDataCrawlData";
import EditMetaDataCrawl from "./pages/manageMetadataCrawls/EditMetaDataCrawl";
import MetadataInsights from "./pages/MetadataInsights";
import LandingPageMarkII from "./pages/LandingPageMarkII";
import LandingPageScroll from "./pages/LandingPageScroll";
import DataObservability from "./pages/DataObservability";
import ManageViews from "./pages/ManageViews/ManageViews";

function App({ instance }) {
  return (
    <div className="App">
      <MsalProvider instance={instance}>
        <AuthProvider>
          <ContextProvider>
            <ContextProviderDCT>
              <NavBarAndLandingPageProvider>
                <Navbar />
                <Routes>
                  <Route
                    index
                    element={
                      <Navigate
                        replace
                        to={"/LandingPageScroll"}
                        // to={LANDING_URL}
                      />
                    }
                  />
                  <Route path="/LandingPage" element={<LandingPage />} />

                  <Route
                    path="/LandingPageMarkII"
                    element={<LandingPageMarkII />}
                  />
                  <Route
                    path="/LandingPageScroll"
                    element={<LandingPageScroll />}
                  />

                  <Route path="/UserInsights" element={<UserInsights />} />

                  <Route
                    path="/MetadataInsights"
                    element={<MetadataInsights />}
                  />

                  <Route
                    path="/DataObservability"
                    element={<DataObservability />}
                  />

                  <Route
                    path="/SearchResults/:criteria/:searchTerm"
                    element={<SearchResults />}
                  />
                  <Route
                    path="/SearchResults/:criteria/:searchTerm/:categoryName/:subCategoryName"
                    element={<SearchResults />}
                  />
                  {/* <Route path="/SearchResults" element={<SearchResults />} /> */}

                  <Route
                    path="/DatasetDetails/:datasetFQN"
                    element={<DatasetDetails />}
                  />
                  <Route path="/DatasetDetails" element={<DatasetDetails />} />

                  <Route path="/myConnections" element={<MyConnections />} />

                  <Route
                    path="/createDataSources"
                    element={<NewDataSourceConnection />}
                  />
                  <Route
                    path="/createDataSources/:connection_id"
                    element={<NewDataSourceConnection />}
                  />

                  <Route path="/publishMetadata" element={<MetadataCrawl />} />
                  <Route
                    path="/publishMetadata/:connectionFqn"
                    element={<MetadataCrawl />}
                  />

                  <Route
                    path="/manageMetadatacrawls"
                    element={<ManageMetadataCrawls />}
                  />
                  <Route
                    path="/metadataCrawls/edit"
                    element={<EditMetaDataCrawl />}
                  />
                  {/* <Route
                    path="/metadataCrawls/new"
                    element={<AddNewMetaDataCrawlData />}
                  /> */}

                  <Route
                    path="/connectiondetail/:connfqn"
                    element={<ConnectionDetail />}
                  />

                  <Route
                    path="/metadataCrawl/:connectionFqnValue"
                    element={<MetadataCrawl />}
                  />

                  <Route path="/bulkupload" element={<BulkView />} />

                  <Route
                    path="/tagsclassifcation"
                    element={<TagsClassificationGlossary />}
                  />
                  <Route
                    path="/glossary"
                    element={<TagsClassificationGlossary />}
                  />

                  <Route path="/manageProducts" element={<ManageProjects />} />

                  <Route path="/addProduct" element={<AddProduct />} />

                  <Route path="/manageViews" element={<ManageViews />} />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </NavBarAndLandingPageProvider>
            </ContextProviderDCT>
          </ContextProvider>
        </AuthProvider>
      </MsalProvider>
    </div>
  );
}

export default App;
