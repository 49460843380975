import { graphlib, layout } from "@dagrejs/dagre"

export const prepareTreeLayout = (nodes, edges, direction = "TB") => {
  const dagreGraph = new graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const isHorizontal = direction === "LR"
  dagreGraph.setGraph({ rankdir: direction })
  // console.log(nodes);

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.style.width + 100,
      height: 50,
    })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  layout(dagreGraph)
  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = isHorizontal ? "left" : "top"
    node.sourcePosition = isHorizontal ? "right" : "bottom"

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - node.style.width / 3,
      y: nodeWithPosition.y - 20 / 2,
    }

    return node
  })

  return { nodes, edges }
}
