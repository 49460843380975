import { MarkerType } from "reactflow"

const datasetNodeStyle = {
  // background: "#eff5f5",
  background: "#fff",
  color: "black",
  border: "none",
  // borderTop: "4px solid #001529",
  width: 340,
  markerEndId: "",
  fontSize: 13,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}

const currentDatasetNodeStyle = {
  background: "#fff",
  color: "black",
  border: "none",
  borderRadius: "10px",
  // borderTop: "10px solid #e71324",
  borderTop: "14px solid #1976d2",
  borderBottom: "0.3px solid #000",
  width: 340,
  markerEndId: "",
  fontSize: 13,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}

const currentTaskNodeStyle = {
  background: "#fff",
  color: "black",
  border: "none",
  borderRadius: "3px",
  borderTop: "10px solid #e71324",
  // borderBottom: "0.3px solid #000",
  width: 320,
  markerEndId: "",
  fontSize: 13,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}

const taskNodeStyle = {
  background: "#fff",
  color: "black",
  // border: "none",
  borderRadius: "3px",
  border: "0.3px solid #007bff",
  borderTop: "6px solid #007bff",

  // borderBottom: "0.3px solid #000",
  width: 320,
  markerEndId: "",
  fontSize: 13,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}

const edgeStyle = {
  stroke: "grey",
  strokeWidth: 1.2,
  strokeDasharray: 5,
}

export const generateDatasetsLineageData = (datasetLineageData) => {
  const position = { x: 100, y: 100 }
  let edges = []
  let nodes = []

  const { currentNode, outbound, inbound } = datasetLineageData
  nodes.push({
    id: currentNode.node.id.split("/")[1],
    // className: `datasets_${ds.fqn}`,
    data: {
      label: currentNode.node.fqn,
      tid: currentNode.node.id.split("/")[1],
      type: `${currentNode.node.id.split("/")[0]}`,
      iconType: currentNode.node.type,
      schema: currentNode.node.datasetSchema,
      columns: currentNode.node?.columns,
    },
    type: `${currentNode.node.id.split("/")[0]}`,
    position,
    style:
      currentNode.node.id.split("/")[0] === "task"
        ? currentTaskNodeStyle
        : datasetNodeStyle,
    // : currentDatasetNodeStyle,
  })
  if (outbound?.length > 0) {
    outbound.forEach((task) => {
      edges.push({
        id: task.edge.edgeId.split("/")[1],
        source: task.edge.edgeFrom.split("/")[1],
        // source: task.node.fqn,
        target: task.edge.edgeTo.split("/")[1],
        // target: ds.name,
        style: edgeStyle,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        animated: false,
      })
      nodes.push({
        id: task.node.id.split("/")[1],
        className: `task_${task.node.id.split("/")[1]}`,
        data: {
          label: task.node.fqn,
          tid: task.node.id.split("/")[1],
          type: `${task.node.id.split("/")[0]}`,
          iconType: task.node?.type,
          schema: task.node?.datasetSchema,
          columns: task.node?.columns,
        },
        type: `${task.node.id.split("/")[0]}`,
        // type: "job",
        position,
        style:
          task.node.id.split("/")[0] === "task"
            ? taskNodeStyle
            : datasetNodeStyle,
      })
    })
  } else {
    edges = edges
  }

  if (inbound?.length > 0) {
    inbound.forEach((task) => {
      edges.push({
        id: task.edge.edgeId.split("/")[1],
        source: task.edge.edgeFrom.split("/")[1],
        // source: ds.fqn,
        // target: task.fqn,
        target: task.edge.edgeTo.split("/")[1],
        style: edgeStyle,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        animated: false,
      })

      nodes.push({
        id: task.node.id.split("/")[1],
        className: `task_${task.node.id.split("/")[1]}`,
        data: {
          label: task.node.fqn,
          tid: task.node.id.split("/")[1],
          type: `${task.node.id.split("/")[0]}`,
          iconType: task.node?.type,
          schema: task.node?.datasetSchema,
          columns: task.node?.columns,
        },
        type: `${task.node.id.split("/")[0]}`,
        // type: "job",
        position,
        style:
          task.node.id.split("/")[0] === "task"
            ? taskNodeStyle
            : datasetNodeStyle,
      })
    })
  } else {
    edges = edges
  }

  return { nodes, edges }
}

export const generateOneClickLineage = (datasetLineageData) => {
  const position = { x: 100, y: 100 }
  let edges = []
  let nodes = []

  const { currentNode, outbound, inbound } = datasetLineageData
  // nodes.push({
  //   id: currentNode.node.id.split("/")[1],
  //   // className: `datasets_${ds.fqn}`,
  //   data: {
  //     label: currentNode.node.fqn,
  //     tid: currentNode.node.id.split("/")[1],
  //     type: `${currentNode.node.id.split("/")[0]}`,
  //     schema: currentNode.node.schema,
  //   },
  //   type: `${currentNode.node.id.split("/")[0]}`,
  //   position,
  //   style:
  //     currentNode.node.id.split("/")[0] === "task"
  //       ? currentTaskNodeStyle
  //       : currentDatasetNodeStyle,
  // });
  if (outbound?.length > 0) {
    outbound.forEach((task) => {
      edges.push({
        id: task.edge.edgeId.split("/")[1],
        source: task.edge.edgeFrom.split("/")[1],
        // source: task.node.fqn,
        target: task.edge.edgeTo.split("/")[1],
        // target: ds.name,
        style: edgeStyle,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        animated: false,
      })
      nodes.push({
        id: task.node.id.split("/")[1],
        className: `task_${task.node.id.split("/")[1]}`,
        data: {
          label: task.node.fqn,
          tid: task.node.id.split("/")[1],
          type: `${task.node.id.split("/")[0]}`,
          iconType: task.node?.type,
          schema: task.node?.datasetSchema,
        },
        type: `${task.node.id.split("/")[0]}`,
        // type: "job",
        position,
        style:
          task.node.id.split("/")[0] === "task"
            ? taskNodeStyle
            : datasetNodeStyle,
      })
    })
  } else {
    edges = edges
  }

  if (inbound?.length > 0) {
    inbound.forEach((task) => {
      edges.push({
        id: task.edge.edgeId.split("/")[1],
        source: task.edge.edgeFrom.split("/")[1],
        // source: ds.fqn,
        // target: task.fqn,
        target: task.edge.edgeTo.split("/")[1],
        style: edgeStyle,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        animated: false,
      })

      nodes.push({
        id: task.node.id.split("/")[1],
        className: `task_${task.node.id.split("/")[1]}`,
        data: {
          label: task.node.fqn,
          tid: task.node.id.split("/")[1],
          type: `${task.node.id.split("/")[0]}`,
          iconType: task.node?.type,
          schema: task.node?.datasetSchema,
        },
        type: `${task.node.id.split("/")[0]}`,
        // type: "job",
        position,
        style:
          task.node.id.split("/")[0] === "task"
            ? taskNodeStyle
            : datasetNodeStyle,
      })
    })
  } else {
    edges = edges
  }

  return { nodes, edges }
}

export const generateJobLineageData = (jobLineageData) => {
  const position = { x: 5, y: 5 }
  let edges = []
  let nodes = []

  const { job, inbound, outbound } = jobLineageData

  nodes.push({
    id: job.name,
    // className: `job_${job.name}`,
    data: { label: job.name, tid: job.fqn, type: "job", schema: job.schema },
    type: "job",
    position,
    style: taskNodeStyle,
  })
  if (outbound.length > 0) {
    outbound.forEach((datasets) => {
      edges.push({
        id: `${datasets.name}_${job.name}`,
        source: job.name,
        target: datasets.name,
        style: edgeStyle,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        animated: false,
      })
      nodes.push({
        id: datasets.name,
        // className: `datasets_${datasets.name}`,
        data: {
          label: datasets.name,
          tid: datasets.id,
          type: "dataset",
          schema: datasets.dataset_schema,
        },
        type: "dataset",
        position,
        style: datasetNodeStyle,
      })
    })
  } else {
    edges = edges
  }

  if (inbound.length > 0) {
    inbound.forEach((datasets) => {
      edges.push({
        id: datasets.edge.edgeId,
        source: datasets.name,
        target: job.name,
        style: edgeStyle,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        animated: false,
      })

      nodes.push({
        id: datasets.name,
        // className: `datasets_${datasets.name}`,
        data: {
          label: datasets.name,
          tid: datasets.id,
          type: "dataset",
          schema: datasets.dataset_schema,
        },
        type: "dataset",
        position,
        style: datasetNodeStyle,
      })
    })
  } else {
    edges = edges
  }

  return { nodes, edges }
}
