import { Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";

const Snowflake = ({
  connectionData,
  onSubmitFun,
  testConnectionSuccess,
  submissionSuccess,
  showLoading,
}) => {
  const [connectionCreds, setConnectionCreds] = useState({
    hostName: "",
    port: "",
    warehouse: "",
    database: "",
    userName: "",
    password: "",
    role: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionCreds((prevCreds) => ({
      ...prevCreds,
      [name]: value,
    }));
  };

  const handleSubmit = (isTestConnection) => {
    const formData = {
      ...connectionData,
      connectionCreds,
    };
    console.log(formData, "Snowflake payload");
    if (onSubmitFun) {
      onSubmitFun(formData, isTestConnection);
    }
  };

  // const handleSubmit = (isTestConnection) => {
  //   const formData = {
  //     ...connectionData,
  //     connectionCreds,
  //   };

  //   if (isTestConnection) {
  //     console.log("Test Connection Payload:", {
  //       hostName: connectionCreds.hostName,
  //       port: connectionCreds.port,
  //       warehouse: connectionCreds.warehouse,
  //       database: connectionCreds.database,
  //       userName: connectionCreds.userName,
  //       password: connectionCreds.password,
  //       role: connectionCreds.role,
  //       ...connectionData,
  //     });
  //   }
  //   // return;
  //   if (onSubmitFun) {
  //     onSubmitFun(formData, isTestConnection);
  //   }
  // };

  return (
    <div>
 
    <div className="individualRow">
        <div className="inputWrapper">
          <label className="labelStyle" htmlFor="hostName">
            Host Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="hostName"
            name="hostName"
            placeholder="Host Name"
            value={connectionCreds.hostName}
            onChange={handleChange}
            style={{
              border: "1px solid #d3d3d3",
              borderRadius: "5px",
              width: "660px",
            }}
          />
        </div>
      </div>

      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="role">
            Role <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="role"
            name="role"
            placeholder="Enter Role"
            onChange={handleChange}
            value={connectionCreds.role}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="port">
            Port <span style={{ color: "red" }}>*</span>
          </label>{" "}
          <br />
          <input
            type="text"
            id="port"
            name="port"
            placeholder="Role"
            value={connectionCreds.port}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="warehouse">
            Warehouse <span style={{ color: "red" }}>*</span>
          </label>{" "}
          <br />
          <input
            type="text"
            id="warehouse"
            name="warehouse"
            placeholder="Warehouse"
            value={connectionCreds.warehouse}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="database">
            Database <span style={{ color: "red" }}>*</span>
          </label>{" "}
          <br />
          <input
            type="text"
            id="database"
            name="database"
            placeholder="Database"
            value={connectionCreds.database}
            onChange={handleChange}
            required
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>

      <div className="individualRow">
        <div className="">
          <label className="labelStyle" htmlFor="userName">
            User Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="userName"
            name="userName"
            placeholder="Enter User Name"
            value={connectionCreds.userName}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div className="passwordWrapper">
          <div>
            <label className="labelStyle" htmlFor="password">
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter Password"
              value={connectionCreds.password}
              onChange={handleChange}
              style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
            />
          </div>
        </div>

        {showLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              color: "#d3d3d3",
            }}
          >
            <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
          </Box>
        ) : (
          <div className="valign_bottom">
            <button
              className="job-btn btn nar-wid1"
              onClick={() => handleSubmit(true)}
              disabled={testConnectionSuccess}
              // disabled={!testConnectionSuccess || submissionSuccess}
            >
              Check Connection
            </button>
          </div>
        )}
      </div>
      <hr></hr>

      <div className="individualRow" style={{ marginTop: "20px" }}>
        <button
          className="job-btn btn"
          type="submit"
          // onSubmit={handleSubmit}
          onClick={() => handleSubmit(false)}
          disabled={!testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Submit
        </button>
        {/* <button
          className="job-btn btn nar-wid"
          onClick={() => handleSubmit(true)}
          disabled={testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Check Connection
        </button> */}
      </div>
    </div>
  );
};

export default Snowflake;
