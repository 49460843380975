import React, { useState, createContext, useEffect, useContext } from "react"
import { AuthContext } from "./AuthContext"
import axios from "axios"
import { useMsal } from "@azure/msal-react"
import { DATABOOK_API, MARTBUILDER_API } from "./serverConfig"
import { protectedResources } from "./msalConfig"

export const NavBarAndLandingPageContext = createContext()

export const NavBarAndLandingPageProvider = ({ children }) => {
  const [roletoggle, setRoleToggle] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState({
    criteria: "all",
    term: "",
  })
  const { instance } = useMsal()
  const { getToken, token, error, isAuthenticated } = useContext(AuthContext)
  const account = instance.getActiveAccount()
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  }

  const [browseFetching, setBrowseFetching] = useState(false)
  const [recentSearch, setRecentSearch] = useState([]);

  const [viewsData, setViewsData] = useState([]);

  // const [widget, setWidgetDataset] = useState({
  //     widgetData: [],
  //     widgetCount: [],
  //     widgetLoad: false
  // });
  // const [schema, setSchemaDataset] = useState({
  //     schemadata: [],
  //     schemaAllData: [],
  //     schemaLoading: false
  // });
  // const [maserskmodel, setByMaerskModel] = useState({
  //     maerskmodelData: [],
  //     keywordCloudData: [],
  //     maerskmodelLoading: false
  // });
  // const [domaindata, setDomainData] = useState({
  //     domainData: [],
  //     domainLoading: false
  // });
  // const [bicatalogData, setBiCatalogData] = useState({
  //     catalogCount: [],
  //     catalogdata: [],
  //     catalogLoading: false
  // });
  // const [kpibicatalogData, setKpiBiCatalogData] = useState({
  //     catalogCount: [],
  //     catalogdata: [],
  //     catalogLoading: false
  // });
  // const [browseData, setBrowseData] = useState({
  //   catalogs: [],
  // })
  const [browseData, setBrowseData] = useState([])

  const BROWSEAPI = `${DATABOOK_API}/browse/catalog/data`

  const getBrowseData = (token) => {
    setBrowseFetching(true)
    console.log("Browse API Data:", token)
    axios
      .get(BROWSEAPI, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log("Browse API Data:", res.data.catalogs)
        // let filtercatalog = res.data.catalogs.filter((cata) => {
        //   console.log("cata:", cata)
        //   return cata.name !== "Product"
        // })
        // console.log("Browse API Data:", filtercatalog)
        // "Product"
        let arrangedData = []
          res.data.catalogs[0]?.categories?.map((category) => {
          if(category?.name==="Business Units"){
            arrangedData[0] = category
          }else if(category?.name==="Domains"){
            arrangedData[1] = category
          }else if(category?.name==="Sub Domains"){
            arrangedData[2] = category
          }else if(category?.name==="Products"){
            arrangedData[3] = category
          }else if(category?.name==="Data Assets"){
            arrangedData[4] = category
          }
        })
        // console.log("arranged",arrangedData)
        setBrowseData(arrangedData)
        // setBrowseData(res.data)
        setBrowseFetching(false)
      })
  }

  const getUserRecentSearches = (token) => {
    axios
      .get(
        `https://telemetry-api.gleafink.com:443/metainsights/telemetry/insights/search/terms/recent_searches/{userEmail}?userEmail=${account.username}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        let userFilteredRes = res.data.filter((item) => item.username === account.username);
        const uniqueRes = userFilteredRes.filter((obj, index) => {
          return index === userFilteredRes.findIndex(o => obj.searchedKeyword === o.searchedKeyword);
      });
        setRecentSearch(uniqueRes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getallviewsdata = async (token) => {
    axios
      .get(`${MARTBUILDER_API}/metainsightsview/getallmetainsightsviews`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let maindata = res.data;
        setViewsData(maindata);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchdatasets = async () => {
    // ;(async () => await getToken())()
    if (token) {
      getBrowseData(token)
      getUserRecentSearches(token)
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken
        getBrowseData(rtoken)
        getUserRecentSearches(rtoken)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const fetchViews = async () => {
    if (token) {
      getallviewsdata(token)
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken
        getallviewsdata(rtoken)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    getToken()
    if (token) {
      fetchdatasets()
      fetchViews()
    }
  }, [token, isAuthenticated])

  return (
    <NavBarAndLandingPageContext.Provider
      value={{
        setRoleToggle,
        roletoggle,
        setSearchKeyword,
        searchKeyword,
        browseData,
        browseFetching,
        recentSearch,
        viewsData,
        fetchViews,
      }}
    >
      {children}
    </NavBarAndLandingPageContext.Provider>
  )
}
