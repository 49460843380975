import React, { useContext, useEffect, useState, useRef } from "react";
import "../styles/Landing-page-scroll.css";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  FormControl,
  Typography,
  Select,
  Avatar,
  Tooltip,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { NavBarAndLandingPageContext } from "../NavBarContextAndLandingPageContext";
import { Card } from "antd";
import { OverlayTrigger } from "react-bootstrap";
import { truncateText } from "../utils/functions";
import SmartDataDiscovery from "../assets/images/Smart-Data-Discovery-3.svg";

const LandingPageScroll = () => {
  const { browseData, browseFetching, recentSearch } = useContext(
    NavBarAndLandingPageContext
  );
  const navigate = useNavigate();
  const cardContainerRef = useRef();

  const [selectedCategory, setSelectedCategory] = useState(browseData[0]);
  const [criteria, setCriteria] = useState("dataset-name");
  const [searchTerm, setSearchTerm] = useState(" ");

  const handleChange = (event) => {
    setCriteria(event.target.value);
  };

  const handleCardClick = (category) => {
    setSelectedCategory(category);
  };

  const handleSearchCardClick = (subCategory, category) => {
    navigate(
      `/SearchResults/${criteria}/${searchTerm}/${category?.name}/${subCategory?.name}`
    );
  };

  const handleScrollBtn = (scrollSide) => {
    if (cardContainerRef.current) {
      if (scrollSide === "left") {
        cardContainerRef.current.scrollBy({ left: -250, behavior: "smooth" });
      }
      if (scrollSide === "right") {
        cardContainerRef.current.scrollBy({ left: 250, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (browseData?.length !== 0) {
      setSelectedCategory(browseData[0]);
    }
  }, [browseData]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          src={SmartDataDiscovery}
          alt="HeadImage"
          style={{
            height: "16rem",
            width: "16rem",
            margin: "2rem auto 0 auto",
          }}
        />
        <div
          style={{
            width: "58rem",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "1rem auto 4rem auto",
          }}
        >
          <FormControl sx={{ m: 0, minWidth: 150 }} size="small">
            <InputLabel id="demo-select-small-label">Category</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={criteria}
              label="Category"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    padding: "0.6rem",
                    height: "18rem",
                  },
                },
              }}
            >
              <ListSubheader
                sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
              >
                <span>Dataset</span>
              </ListSubheader>
              <MenuItem value={"dataset-name"}>Name</MenuItem>
              <MenuItem value={"dataset-desc"}>Description</MenuItem>
              <MenuItem value={"dataset-tag"}>Tag</MenuItem>
              <MenuItem value={"dataset-classification"}>
                Classification
              </MenuItem>
              <MenuItem value={"dataset-sensitivity"}>Sensitivity</MenuItem>
              <ListSubheader
                sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
              >
                <span>Column</span>
              </ListSubheader>
              <MenuItem value={"column-name"}>Name</MenuItem>
              <MenuItem value={"column-desc"}>Description</MenuItem>
              <MenuItem value={"column-tag"}>Tag</MenuItem>
              <MenuItem value={"column-classification"}>
                Classification
              </MenuItem>
              <MenuItem value={"column-sensitivity"}>Sensitivity</MenuItem>
              <ListSubheader
                sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
              >
                <span>Others</span>
              </ListSubheader>
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"ai"}>AI</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <FormControl
              sx={{ m: 0, width: 590 }}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-search">
                Search here
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    navigate(`/SearchResults/${criteria}/${searchTerm}`);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        backgroundColor: "#d8d8d8",
                        borderRadius: "4px",
                      }}
                      aria-label="SearchIcon"
                      onClick={() => {
                        navigate(`/SearchResults/${criteria}/${searchTerm}`);
                      }}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search here"
              />
            </FormControl>
            {recentSearch?.length !== 0 && (
              <Typography
                color="text.secondary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  width: "590px",
                }}
              >
                Recent searches:{"  "}
                {recentSearch?.slice(0, 6).map((rec, idx) => (
                  <p
                    key={idx}
                    className="dhc-header-message-classi m-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `/SearchResults/${rec?.searchedCriteria}/${rec?.searchedKeyword}`
                      );
                    }}
                  >
                    {rec?.searchedKeyword}
                  </p>
                ))}{" "}
              </Typography>
            )}
          </div>
        </div>
      </div>
      {browseFetching ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "6rem",
          }}
        >
          <Skeleton variant="rounded" width={"70%"} height={250} />
          <Skeleton variant="rounded" width={"70%"} height={250} />
        </div>
      ) : (
        <>
          <div>
            <h5
              style={{
                width: "80%",
                textAlign: "left",
                padding: "0 2rem",
                margin: "0 auto",
              }}
            >
              Categories
            </h5>
            <div className="category-card-outer">
              <FaArrowCircleLeft
                size={24}
                style={{ color: "#1890ff", cursor: "pointer" }}
                onClick={() => {
                  handleScrollBtn("left");
                }}
              />
              <div className="card-container" ref={cardContainerRef}>
                {browseData.map((category, index) => (
                  <Card
                    hoverable
                    key={index}
                    className={`LandingCategoryCard ${
                      category?.name === selectedCategory?.name
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleCardClick(category)}
                  >
                    {/* {category.name} */}
                    {/* <Card style={{ width: 300 }}> */}
                    <div>
                      {category?.name +
                        " (" +
                        category?.subCategories?.length +
                        ")"}
                    </div>
                    {/* </Card> */}
                  </Card>
                ))}
              </div>
              <FaArrowCircleRight
                size={24}
                style={{ color: "#1890ff", cursor: "pointer" }}
                onClick={() => {
                  handleScrollBtn("right");
                }}
              />
            </div>
          </div>

          <div className="content">
            {/* <h2>{selectedCategory.name}</h2> */}
            {/* <p>{selectedCategory.content}</p> */}
            <div className="content-body">
              {selectedCategory?.subCategories?.map((subCategory, index) => (
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  delay={{ show: 150, hide: 280 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      <span>{subCategory?.name}</span>
                    </Tooltip>
                  }
                >
                  <Card
                    hoverable
                    bordered={true}
                    className="content-card"
                    key="1"
                    onClick={() =>
                      handleSearchCardClick(subCategory, selectedCategory)
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "8px",
                      }}
                    >
                      {/* {subCategory.name} */}
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <Avatar
                          sx={{ bgcolor: "#87CEFA", margin: "0 auto" }}
                          variant="rounded"
                        >
                          {subCategory?.name?.substring(0, 1)}
                        </Avatar>
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          // justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="p"
                          component="div"
                          style={{ width: "60%" }}
                        >
                          {truncateText(subCategory?.name)}
                        </Typography>
                        <Typography color="text.secondary">
                          {subCategory?.count} assets
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </OverlayTrigger>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LandingPageScroll;
