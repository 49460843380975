import axios from "axios"
import React, { useRef, useState } from "react"
import {
  getDatasetDetails,
  getLineageTreeData,
} from "../../../../services/details.service"
import { LOADING_STATUS } from "../../../../services/httpService"
import {
  generateDatasetsLineageData,
  generateOneClickLineage,
} from "../utils/function"

const useDatasetLineageHook = (dispatch) => {
  const [linageFromFqnApiStatus, setLinageFromFqnApiStatus] = useState(
    LOADING_STATUS.NOT_STARTED
  )
  const [datasetDetailFromFqnApiStatus, setDatasetDetailFromFqnApiStatus] =
    useState(LOADING_STATUS.NOT_STARTED)
  const lineageFromFqnCancelToken = useRef(null)
  const DatasetDetailsFromFqnCancelToken = useRef(null)

  const fetchLineageFromFqn = async (
    token,
    fqn,
    direction,
    depth,
    nodeClick = false
  ) => {
    lineageFromFqnCancelToken.current = axios.CancelToken.source()
    try {
      const res = await getLineageTreeData(
        token,
        fqn,
        direction,
        depth,
        lineageFromFqnCancelToken.current.token
      )
      setLinageFromFqnApiStatus(LOADING_STATUS.LOADED)

      // console.log(res, "hello")
      if (nodeClick) {
        // GENERATE THE TREE IF ANY NODE IS CLICKED
        dispatch({
          type: "SET_TREE_LINEAGE_DATA",
          payload: generateOneClickLineage(res),
        })
      } else {
        dispatch({
          type: "CLEAR_TREE_DATA",
        })
        dispatch({
          type: "SET_TREE_LINEAGE_DATA",
          payload: generateDatasetsLineageData(res),
        })
      }
    } catch (err) {
      setLinageFromFqnApiStatus(LOADING_STATUS.ERROR)
      if (axios.isCancel(err)) {
        dispatch({ type: "CLEAR_TREE_DATA" })
        return
      } else {
        console.error(err)
      }
    }
  }

  const fetchDetasetDetails = async (token, fqn) => {
    DatasetDetailsFromFqnCancelToken.current = axios.CancelToken.source()
    dispatch({ type: "CLEAN_SIDE_PANEL_DATA" })
    try {
      dispatch({
        type: "SET_SHOW_SIDE_PANEL",
      })
      const res = await getDatasetDetails(
        token,
        fqn,
        DatasetDetailsFromFqnCancelToken.current.token
      )
      setDatasetDetailFromFqnApiStatus(LOADING_STATUS.LOADED)
      dispatch({
        type: "UPDATE_SIDE_PANEL_DATA",
        payload: res.dataset,
      })
    } catch (err) {
      if (axios.isCancel(err)) {
        dispatch({ type: "CLEAN_SIDE_PANEL_DATA" })
        return
      } else {
        console.error(err)
      }
    }
  }

  return {
    fetchLineageFromFqn,
    linageFromFqnApiStatus,
    fetchDetasetDetails,
    datasetDetailFromFqnApiStatus,
  }
}

export default useDatasetLineageHook
