import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { GoSidebarExpand } from "react-icons/go";
import Postgres from "../../assets/images/DatabaseIcons/postgre.svg";
import { useMsal } from "@azure/msal-react";
import { Logout, Settings } from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { protectedResources } from "../../msalConfig";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SideDrawerList from "./SideDrawer/SideDrawerList";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import { NavBarAndLandingPageContext } from "../../NavBarContextAndLandingPageContext";

const components = [
  // {
  //   title: "My Connections",
  //   href: "/myConnections",
  // },
  {
    title: "Manage Products",
    href: "/manageProducts",
  },
  {
    title: "Manage Datasources",
    href: "/myConnections",
  },
  {
    title: "Manage Metadata",
    href: "/manageMetadatacrawls",
  },
  {
    title: "Manage Classifications",
    href: "/tagsclassifcation",
  },
  {
    title: "Bulk Upload Glossary",
    href: "/bulkupload",
  },
  {
    title: "Manage Views",
    href: "/manageViews",
  },
];

function Navbar() {
  const { getToken, token, error } = useContext(AuthContext);
  const { viewsData } = useContext(NavBarAndLandingPageContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [ownedAssets, setOwnedAssets] = useState([]);
  const [subsAssets, setSubsAssets] = useState([]);
  const [buzzBox, setBuzzBox] = useState([]);

  const [criteria, setCriteria] = useState("dataset-name");
  const [searchTerm, setSearchTerm] = useState("");
  const [accountEl, setAccountEl] = React.useState(null);
  const [chooseView, setChooseView] = useState("");

  const handleChange = (event) => {
    setCriteria(event.target.value);
  };

  const handleViewChange = (event) => {
    setChooseView(event.target.value);
  };

  const handleAccountClick = (event) => {
    setAccountEl(event.currentTarget);
  };

  const handleAccMenuClose = () => {
    setAccountEl(null);
  };

  const handleLogout = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  const columns = useMemo(() => [
    {
      accessorKey: "type",
      header: "Type",
      size: 80,
      Cell: ({ cell }) =>
        cell.row.original.type === "Dataset" && (
          <BsDatabaseFillExclamation
            style={{
              height: "1.2rem",
              width: "1.2rem",
              // paddingBottom: "8px",
              color: "#1890ff",
              marginLeft: "25%",
            }}
          />
        ),
    },
    {
      accessorKey: "datasourceType",
      header: "Datasource Type",
      size: 160,
      Cell: ({ cell }) =>
        cell.row.original.datasourceType === "Postgres" && (
          <img
            src={Postgres}
            style={{ marginLeft: "40%", height: "1.4rem", width: "1.4rem" }}
            alt="database Icon"
          />
        ),
    },
    {
      accessorKey: "fqn",
      header: "Name",
      size: 140,
      Cell: ({ cell }) => (
        <p
          style={{ marginBottom: "0" }}
          onClick={() => {
            navigate(`/DatasetDetails/${cell.row.original.fqn}`);
            setOpenDrawer(false);
          }}
        >
          {cell.row.original.fqn.split(".").pop()}
        </p>
      ),
    },
  ]);

  const getUserBuzzbox = (token) => {
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/datasets/user/subscribed_datasets/auditlogs`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setBuzzBox(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserOwnedAsset = (token) => {
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/datasets/user/owned_datasets`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setOwnedAssets(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserSubscribedAsset = (token) => {
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/datasets/user/subscribed_datasets`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setSubsAssets(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callapiwithtoken = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    callapiwithtoken(getUserOwnedAsset);
    callapiwithtoken(getUserSubscribedAsset);
    callapiwithtoken(getUserBuzzbox);
  }, [openDrawer, instance]);

  // const DrawerList = (
  //   <Box sx={{ width: 450, height: "100%" }} role="presentation">
  //     <Card
  //       sx={{
  //         margin: "7px",
  //         width: "100%",
  //         height: "31.5%",
  //         overflow: "auto",
  //         cursor: "pointer",
  //         borderRadius: "8px",
  //       }}
  //     >
  //       {/* <CardActionArea sx={{ height: "100%" }}> */}
  //       <CardContent
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "flex-start",
  //           gap: "8px",
  //           backgroundColor: "#E6F4FF",
  //         }}
  //       >
  //         <Typography variant="p" component="div" style={{ margin: "0 auto" }}>
  //           Buzz Box
  //         </Typography>
  //       </CardContent>
  //       <div style={{ padding: "0.2rem 0.4rem" }}>
  //         {buzzBox?.length !== 0 ? (
  //           <>
  //             {buzzBox?.slice(0, 5).map((buzz, idx) => (
  //               <p
  //                 className="dhc-header-message-classi m-1"
  //                 key={idx}
  //                 style={{
  //                   width: "100%",
  //                   fontWeight: "400",
  //                   fontSize: "0.875rem",
  //                   // lineHeight: "1.43",
  //                 }}
  //               >{`${buzz.dataAssetFqn}'s ${buzz.resource} ${buzz.action}d`}</p>
  //             ))}
  //           </>
  //         ) : (
  //           <p
  //             className="dhc-header-message-classi m-1"
  //             style={{
  //               width: "100%",
  //               fontWeight: "400",
  //               fontSize: "0.875rem",
  //               // lineHeight: "1.43",
  //             }}
  //           >
  //             {"There is no notifications to display :)"}
  //           </p>
  //         )}
  //       </div>
  //       {/* </CardActionArea> */}
  //     </Card>
  //     <Divider />
  //     <Card
  //       sx={{
  //         margin: "7px",
  //         width: "100%",
  //         height: "31.5%",
  //         cursor: "pointer",
  //         borderRadius: "8px",
  //         overflow: "auto",
  //       }}
  //     >
  //       {/* <CardActionArea sx={{ height: "100%" }}> */}
  //       <CardContent
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           justifyContent: "flex-start",
  //           gap: "8px",
  //           backgroundColor: "#E6F4FF",
  //           padding: "0.4rem",
  //         }}
  //       >
  //         <Typography variant="p" component="div" style={{ margin: "0 auto" }}>
  //           Owned Assets
  //         </Typography>
  //         <MaterialReactTable
  //           displayColumnDefOptions={{
  //             "mrt-row-actions": {
  //               muiTableHeadCellProps: {
  //                 align: "center",
  //                 sx: { padding: "0", width: "98%" },
  //               },
  //               muiTableBodyCellProps: {
  //                 sx: { padding: "0", width: "98%" },
  //               },
  //             },
  //           }}
  //           muiTableHeadCellProps={{
  //             sx: {
  //               paddingLeft: "0.6rem",
  //             },
  //           }}
  //           muiTableBodyCellProps={{
  //             sx: {
  //               paddingLeft: "0.6rem",
  //             },
  //           }}
  //           initialState={{
  //             density: "compact",
  //             pagination: { pageSize: 5, pageIndex: 0 },
  //           }}
  //           columns={columns}
  //           data={ownedAssets}
  //           enableFullScreenToggle={false}
  //           enableDensityToggle={false}
  //           enableHiding={false}
  //           enableColumnActions={false}
  //           enableColumnFilters={false}
  //           enableKeyboardShortcuts={false}
  //           enablePagination={false}
  //           enableSorting={false}
  //         />
  //       </CardContent>
  //       {/* </CardActionArea> */}
  //     </Card>
  //     <Divider />
  //     <Card
  //       sx={{
  //         margin: "7px",
  //         width: "100%",
  //         height: "31.5%",
  //         cursor: "pointer",
  //         borderRadius: "8px",
  //         overflow: "auto",
  //       }}
  //     >
  //       {/* <CardActionArea sx={{ height: "100%" }}> */}
  //       <CardContent
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           justifyContent: "flex-start",
  //           gap: "8px",
  //           backgroundColor: "#E6F4FF",
  //           padding: "0.4rem",
  //         }}
  //       >
  //         <Typography variant="p" component="div" style={{ margin: "0 auto" }}>
  //           Subscribed Assets
  //         </Typography>
  //         <MaterialReactTable
  //           displayColumnDefOptions={{
  //             "mrt-row-actions": {
  //               muiTableHeadCellProps: {
  //                 align: "center",
  //                 sx: { padding: "0" },
  //               },
  //               muiTableBodyCellProps: {
  //                 sx: { padding: "0" },
  //               },
  //             },
  //           }}
  //           muiTableHeadCellProps={{
  //             sx: {
  //               paddingLeft: "0.6rem",
  //             },
  //           }}
  //           muiTableBodyCellProps={{
  //             sx: {
  //               paddingLeft: "0.6rem",
  //             },
  //           }}
  //           initialState={{
  //             density: "compact",
  //             pagination: { pageSize: 5, pageIndex: 0 },
  //           }}
  //           columns={columns}
  //           data={subsAssets}
  //           enableFullScreenToggle={false}
  //           enableDensityToggle={false}
  //           enableHiding={false}
  //           enableColumnActions={false}
  //           enableColumnFilters={false}
  //           enableKeyboardShortcuts={false}
  //           enablePagination={false}
  //           enableSorting={false}
  //         />
  //       </CardContent>
  //       {/* </CardActionArea> */}
  //     </Card>
  //   </Box>
  // )

  return (
    <div
      style={{
        width: "100%",
        position: "sticky",
        top: "0",
        backgroundColor: "#ffffff",
        padding: "0.6rem 1.4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // borderBottom: "1px solid #708090",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
        zIndex: "100",
      }}
    >
      <Link href="/LandingPageScroll" underline="none">
        <div style={{ fontSize: "20px" }}>
          <span>
            Met
            <span
              style={{
                color: "#38bdf8",
                borderBottom: "4px solid #facc15",
                padding: "0 0.14rem",
              }}
            >
              AI
            </span>
            nsights
          </span>
        </div>
      </Link>
      {window.location.pathname !== "/LandingPage" &&
        window.location.pathname !== "/LandingPageScroll" && (
          <div
            style={{
              width: "28rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Select>
            <SelectTrigger className="w-[180px] justify-center gap-4">
              <SelectValue style={{ backgroundColor: "#708090" }} placeholder="All" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="All">All</SelectItem>
                <SelectLabel>Dataset</SelectLabel>
                <SelectItem value="Dataset-Name">Name</SelectItem>
                <SelectItem value="Dataset-Type">Type</SelectItem>
                <SelectLabel>Column</SelectLabel>
                <SelectItem value="Column-Name">Name</SelectItem>
                <SelectItem value="Column-Type">Type</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select> */}
            <FormControl sx={{ m: 0, minWidth: 150 }} size="small">
              <InputLabel id="demo-select-small-label">Category</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={criteria}
                label="Category"
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      padding: "0.6rem",
                      height: "18rem",
                    },
                  },
                }}
              >
                <ListSubheader
                  sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
                >
                  <span>Dataset</span>
                </ListSubheader>
                <MenuItem value={"dataset-name"}>Name</MenuItem>
                <MenuItem value={"dataset-desc"}>Description</MenuItem>
                <MenuItem value={"dataset-tag"}>Tag</MenuItem>
                <MenuItem value={"dataset-classification"}>
                  Classification
                </MenuItem>
                <MenuItem value={"dataset-sensitivity"}>Sensitivity</MenuItem>
                <ListSubheader
                  sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
                >
                  <span>Column</span>
                </ListSubheader>
                <MenuItem value={"column-name"}>Name</MenuItem>
                <MenuItem value={"column-desc"}>Description</MenuItem>
                <MenuItem value={"column-tag"}>Tag</MenuItem>
                <MenuItem value={"column-classification"}>
                  Classification
                </MenuItem>
                <MenuItem value={"column-sensitivity"}>Sensitivity</MenuItem>
                <ListSubheader
                  sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
                >
                  <span>Others</span>
                </ListSubheader>
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"ai"}>AI</MenuItem>
              </Select>
            </FormControl>
            {/* <Input type="text" placeholder="Search here" /> */}
            {/* <TextField
            sx={{ width: 890 }}
            id="outlined-basic"
            label="Search here"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          /> */}
            <FormControl
              sx={{ m: 0, width: 890 }}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-search">
                Search here
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    navigate(`/SearchResults/${criteria}/${searchTerm}`);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        backgroundColor: "#d8d8d8",
                        borderRadius: "4px",
                      }}
                      aria-label="SearchIcon"
                      onClick={() => {
                        navigate(`/SearchResults/${criteria}/${searchTerm}`);
                      }}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search here"
              />
            </FormControl>
          </div>
        )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <FormControl sx={{ m: 0, minWidth: 220 }} size="small">
          <InputLabel id="demo-select-small-label">Select View</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={chooseView}
            label="Select View"
            onChange={handleViewChange}
            style={{ backgroundColor: "#E6F4FF" }}
            MenuProps={{
              PaperProps: {
                sx: {
                  padding: "0.6rem",
                  // height: "18rem",
                },
              },
            }}
          >
            {viewsData.map((view) => (
              <MenuItem
                style={{ backgroundColor: "#ffffff" }}
                value={view.name}
                key={view.id}
              >
                {view.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 0, minWidth: 220 }} size="small">
          <InputLabel id="demo-simple-select-label">Advanced</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={age}
            label="Advanced"
            // onChange={handleChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  padding: "0.6rem",
                  height: "18rem",
                },
              },
            }}
          >
            <ListSubheader
              sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
            >
              <span>Publish</span>
            </ListSubheader>
            {components.map((component) => (
              <MenuItem
                key={component.title}
                value={component.title}
                onClick={() => navigate(component.href)}
              >
                {component.title}
              </MenuItem>
            ))}
            <ListSubheader
              sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
            >
              <span>Insights</span>
            </ListSubheader>
            <MenuItem
              value={"User Insights"}
              onClick={() => navigate("/UserInsights")}
            >
              User Insights
            </MenuItem>
            <MenuItem
              value={"User Behavior"}
              // TODO
              // onClick={() => navigate("/UserInsights")}
            >
              <a
                href="https://clarity.microsoft.com/projects/view/p46phde5fz/dashboard?date=Last%203%20days"
                target="_blank"
                rel="noopener noreferrer"
              >
                User Behavior
              </a>
            </MenuItem>
            <MenuItem
              value={"Metadata Insights"}
              onClick={() => navigate("/metadataInsights")}
            >
              Metadata Insights
            </MenuItem>
            <MenuItem
              value={"Data Observability"}
              onClick={() => navigate("/DataObservability")}
            >
              Data Observability
            </MenuItem>
            <ListSubheader
              sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
            >
              <span>AI Assistant</span>
            </ListSubheader>
            <MenuItem
              value={"Metadata Assistant"}
              // onClick={() => navigate("https://jarvis.gleafink.com/landing", { replace: true })}
              onClick={() =>
                (window.location.href = "https://jarvis.gleafink.com/landing")
              }
            >
              Metadata Assistant
            </MenuItem>
            <MenuItem
              value={"Data Assistant"}
              onClick={() =>
                (window.location.href = "https://jarvis.gleafink.com/landing")
              }
            >
              Data Assistant
            </MenuItem>
          </Select>
        </FormControl>

        <Avatar
          sx={{ width: "2rem", height: "2rem", cursor: "pointer" }}
          alt={account?.name
            ?.split(" ")
            .map((n) => n[0])
            .join("")}
          // src="https://github.com/shadcn.png"
          onClick={handleAccountClick}
        >
          <AccountCircleIcon fontSize="large" />
        </Avatar>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip">
              <span>Buzzbox and other assets</span>
            </Tooltip>
          }
        >
          <div
            style={{
              padding: "0",
              margin: "0",
              cursor: "pointer",
            }}
            onClick={() => setOpenDrawer(true)}
          >
            <GoSidebarExpand
              size={30}
              style={{
                color: "#D3D3D3",
                padding: "0",
                margin: "0",
              }}
            />
          </div>
        </OverlayTrigger>
      </div>
      <Menu
        anchorEl={accountEl}
        id="account-menu"
        open={Boolean(accountEl)}
        onClose={handleAccMenuClose}
        onClick={handleAccMenuClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleAccMenuClose}>
          <Avatar /> My profile
        </MenuItem>
        <MenuItem onClick={handleAccMenuClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {/* {DrawerList} */}
        <SideDrawerList
          buzzBox={buzzBox}
          columns={columns}
          ownedAssets={ownedAssets}
          subsAssets={subsAssets}
        />
      </Drawer>
    </div>
  );
}

export default Navbar;
