import React, { useContext, useState } from "react"

import "../nodeStyles/datasetNode.style.css"

import { Handle, Position } from "reactflow"
import { IconButton, Tooltip, Typography } from "@mui/material"
import { AuthContext } from "../../../../AuthContext"
import {
  AddCircleOutline,
  BackupTable,
  InsertChart,
  SpaceDashboard,
  Info,
} from "@mui/icons-material"
import { PiCubeTransparentFill } from "react-icons/pi"
import { BsDatabaseFillExclamation } from "react-icons/bs"

const DatasetNode = ({ data, isConnectable }) => {
  const {
    schema,
    label,
    dataSourceName,
    iconType,
    onInfoClick,
    columns,
    expandNode,
  } = data

  const [searchText, setSearchText] = useState("")

  const { getToken, token, error } = useContext(AuthContext)
  const shortenString = (longString) => {
    if (longString.length <= 30) {
      return longString
    }

    const firstPart = longString.slice(0, 10)
    const lastPart = longString.slice(-14)

    return `${firstPart}...${lastPart}`
  }

  const handleInfoClick = () => {
    if (!token) {
      getToken()
    } else {
      onInfoClick(token, data.label)
    }
  }

  const filteredItems = columns?.filter((item) =>
    item?.fqn?.toLowerCase().includes(searchText.toLowerCase())
  )
  // const ToolTipContent = () => {
  //   return (
  //     <ul>
  //       <li>Name: {label}</li>
  //       {type === "task" ? (
  //         <li>tid: {data.tid}</li>
  //       ) : (
  //         <li>uuid: {data.uuid}</li>
  //       )}
  //     </ul>
  //   );
  // };

  return (
    <div style={{ padding: "8px 0" }}>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        // style={{ width: "30px", height: "30px" }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div className="dataset-node-static--style">
          <div className="dataset-node-top">
            <div
              style={{
                marginLeft: 6,
              }}
            >
              {iconType === "datacube" && (
                <PiCubeTransparentFill
                  style={{ color: "#1890ff", fontSize: "31px" }}
                  size={28}
                />
              )}
              {iconType === "Dataset" && (
                <BsDatabaseFillExclamation
                  style={{ color: "#1890ff", fontSize: "31px" }}
                  size={24}
                />
              )}
              {iconType === "dashboard" && (
                <SpaceDashboard
                  style={{ color: "#1890ff", fontSize: "31px" }}
                  size={24}
                />
              )}
              {iconType === "chart" && (
                <InsertChart
                  style={{ color: "#1890ff", fontSize: "31px" }}
                  size={24}
                />
              )}
            </div>
            <div className="dataset-node-content">
              <Typography variant="p" component="p" style={{ width: 130 }}>
                <Tooltip placement="left-start" title={label} arrow>
                  <span>{label && shortenString(label)}</span>
                </Tooltip>
              </Typography>
              <Typography
                variant="p"
                component="p"
                style={{ width: 130, fontSize: 10, textAlign: "left" }}
              >
                schema: {schema && shortenString(schema)}
              </Typography>
            </div>
            <Info
              style={{ color: "#eeb57c", fontSize: "24px", cursor: "pointer" }}
              onClick={handleInfoClick}
            />
          </div>
          {/* columns in the node */}
          {expandNode && columns?.length > 0 && (
            <div className="dataset-node-top-bottom">
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <ul className="column-list">
                {filteredItems.length > 0 ? (
                  filteredItems.map((item, index) => (
                    <>
                      {item.fqn && (
                        <li className="column-list-item" key={index}>
                          <p style={{ margin: 0 }}>
                            {item.fqn.split(".").pop()}
                          </p>
                        </li>
                      )}
                    </>
                  ))
                ) : (
                  <li
                    style={{
                      padding: "8px",
                      textAlign: "center",
                      color: "#aaa",
                    }}
                  >
                    No items found
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        <div className="expand_collapse_button">
          <span style={{ fontSize: "20px", color: "gray" }}>-</span>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  )
}

export default DatasetNode
