import { getBaseURLs } from "../config/base-url.config"
import { DATASET_DETAIL_ENDPOINT, LINEAGE_ENDPOINT } from "./api-endpoints"
import { httpClient } from "./httpService"

const baseUrl = getBaseURLs("development")

export async function getLineageTreeData(
  token,
  fqn,
  direction,
  depth,
  cancelToken
) {
  const client = httpClient(baseUrl.metaInsightsTelemetry)

  return await client.get(`${LINEAGE_ENDPOINT}/${fqn}/${direction}/${depth}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    cancelToken,
  })
}
export async function getDatasetDetails(token, fqn, cancelToken) {
  const client = httpClient(baseUrl.metaInsightsTelemetry)

  return await client.get(`${DATASET_DETAIL_ENDPOINT}/${fqn}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    cancelToken,
  })
}
