import { Close } from "@mui/icons-material"
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
// import Wrapper from "./wrapper";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  getIncomers,
  getOutgoers,
  MiniMap,
  ReactFlowProvider,
} from "reactflow"

import "reactflow/dist/style.css"
import "./nodeStyles/infoSidePanel.style.css"

import { Switch } from "@mui/material"
import { Select } from "antd"
import { isEqual } from "lodash"
import styled from "styled-components"
import { useValue } from "../../../context/ContextProvider"
import SlideInfoPanel from "./nodeInfoPanel/SlideInfoPanel"
import DatasetNode from "./nodes/DatasetNode"
import JobNode from "./nodes/JobNode"
import { prepareTreeLayout } from "./utils"

export const StyledControls = styled(Controls)`
  display: flex;
  top: 27px;
  right: 36px;
  left: auto;
  bottom: auto;
  .react-flow__controls-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`

const depthOption = [2, 4, 6, 8, 10, 12, 14, 16]

const LineageTree = ({ treeData, onNodeItemClick, onNodeInfoClick }) => {
  const [selectedItem, setSelectedItem] = useState(null)

  const panelRef = useRef(null)
  const {
    state: { lineageDepth, sidePanelShow, sidePanelData, lineageView },
    dispatch,
  } = useValue()

  const handleChange = (value) => {
    dispatch({ type: "UPDATE_LINEAGE_DEPTH", payload: value })
  }
  // const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const [nodes, setNodes] = useState(treeData.nodes)
  const [edges, setEdges] = useState(treeData.edges)

  const onNodesChange = useCallback(
    (changes) => setNodes((ns) => applyNodeChanges(changes, ns)),
    []
  )
  const onEdgesChange = useCallback(
    (changes) => setEdges((es) => applyEdgeChanges(changes, es)),
    []
  )

  const handleLineageViewChange = () => {
    dispatch({ type: "UPDATE_LINEAGE_TYPE", payload: !lineageView.columns })
  }

  useEffect(() => {
    const { nodes, edges } = prepareTreeLayout(
      treeData.nodes,
      treeData.edges,
      "LR"
    )
    setNodes(nodes)
    setEdges(edges)
  }, [treeData.nodes, treeData.edges])

  // const onConnect = useCallback(
  //   (params) =>
  //     setEdges((eds) =>
  //       addEdge(
  //         { ...params, type: ConnectionLineType.SmoothStep, animated: true },
  //         eds
  //       )
  //     ),
  //   []
  // );

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = prepareTreeLayout(
        nodes,
        edges,
        direction
      )

      setNodes([...layoutedNodes])
      setEdges([...layoutedEdges])
    },
    [nodes, edges]
  )

  // Close panel
  const closePanel = () => {
    dispatch({ type: "SET_HIDE_SIDE_PANEL" })
  }

  const handleNodeDoubleClick = (node) => {
    if (node) {
      const { type, tid } = node.data
      onNodeItemClick(type, tid)
    }
  }
  // const handleNodeSingleClick = (node) => {
  //   if (node) {
  //     // const { type, tid } = node.data
  //     // onNodeItemClick(type, tid)
  //     console.log(node, "node")
  //     openPanel(node)
  //   }
  // }

  const handleNodeMouseEnter = (node) => {
    if (node && nodes && edges) {
      const allIncomers = getIncomers(node, nodes, edges)
      const allOutgoers = getOutgoers(node, nodes, edges)

      setNodes((prevNodes) => {
        return prevNodes.map((el) => {
          const incomerIds = allIncomers.map((i) => i.id)
          const outgoerIds = allOutgoers.map((o) => o.id)

          if (allOutgoers.length > 0 || allIncomers.length > 0) {
            const highlight =
              el.id === node.id ||
              incomerIds.includes(el.id) ||
              outgoerIds.includes(el.id)

            el.style = {
              ...el.style,
              opacity: highlight ? 1 : 0.25,
            }
          }
          return el
        })
      })

      setEdges((prevEdges) => {
        return prevEdges.map((el) => {
          const incomerIds = allIncomers.map((i) => i.id)
          const outgoerIds = allOutgoers.map((o) => o.id)

          const animated =
            (incomerIds.includes(el.source) &&
              (incomerIds.includes(el.target) || node.id === el.target)) ||
            node.id === el.source

          el.animated = animated

          el.style = {
            ...el.style,
            stroke: animated ? "#e71324" : "grey",
            opacity: animated ? 1 : 0.25,
          }

          return el
        })
      })
    }
  }

  const handleNodeMouseLeave = (node) => {
    setNodes((prevNodes) => {
      return prevNodes.map((el) => {
        el.style = {
          ...el.style,
          opacity: 1,
          cursor: "grab",
        }
        return el
      })
    })
    setEdges((prevEdges) => {
      return prevEdges.map((el) => {
        el.animated = false

        el.style = {
          ...el.style,
          stroke: "grey",
          opacity: 1,
        }

        return el
      })
    })
  }

  const handleNodeDragStart = (node) => {
    setNodes((prevNodes) => {
      return prevNodes.map((el) => {
        const isCurrentNode = el.id === node.id
        if (isCurrentNode) {
          el.style = {
            ...el.style,
            opacity: 1,
            cursor: "grabbing",
          }
        }
        return el
      })
    })
  }

  const handleNodeDragStop = (node) => {
    setNodes((prevNodes) => {
      return prevNodes.map((el) => {
        const isCurrentNode = el.id === node.id
        if (isCurrentNode) {
          el.style = {
            ...el.style,
            opacity: 1,
            cursor: "grab",
          }
        }
        return el
      })
    })
  }

  const nodeTypes = useMemo(
    () => ({
      dataset_version: DatasetNode,
      task: JobNode,
    }),
    []
  )

  // Handle clicks outside the panel
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        closePanel()
      }
    }

    // Add event listener when panel is open
    if (sidePanelShow) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [sidePanelShow])

  return (
    // <Wrapper className="react-flow-tree">
    <div
      style={{
        width: "100%",
        height: "64vh",
        top: 0,
        left: 0,
        background: "#fff",
        position: "relative",
        overflow: "hidden",
        padding: "0",
      }}
    >
      {/* filters */}
      <div
        style={{
          height: "42px",
          padding: "4px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <div style={{ alignItems: "center", display: "flex", gap: "6px" }}>
          <label>Depth</label>
          <Select value={lineageDepth} onChange={handleChange}>
            {depthOption.map((d) => (
              <Select.Option key={d} value={d} style={{ p: 0 }}>
                {d}
              </Select.Option>
            ))}
          </Select>
          {/* <FormHelperText>To traverse in lineage.</FormHelperText> */}
        </div>
        <div style={{ alignItems: "center", display: "flex", width: "200px" }}>
          <label>Expanded View</label>
          <Switch
            checked={lineageView.columns}
            onChange={handleLineageViewChange}
            name="expandedView"
            size="medium"
          />
        </div>
      </div>
      <div style={{ height: "94.5%" }}>
        <ReactFlowProvider>
          <ReactFlow
            defaultzoom={0}
            // minZoom={0.5}
            // maxZoom={1.5}
            elementsSelectable={true}
            nodesDraggable={true}
            selectNodesOnDrag={true}
            nodesConnectable={true}
            nodes={
              lineageView.columns
                ? nodes.map((node) => ({
                    ...node,
                    data: {
                      ...node.data,
                      onInfoClick: onNodeInfoClick,
                      expandNode: lineageView.columns,
                    },
                  }))
                : nodes.map((node) => ({
                    ...node,
                    data: {
                      ...node.data,
                      onInfoClick: onNodeInfoClick,
                    },
                  }))
            }
            edges={edges}
            nodeTypes={nodeTypes}
            //
            connectionLineType="step"
            defaultposition={[350, 350]}
            defaultMarkerColor={"grey"}
            onLoad={() => onLayout("LR")}
            style={{ background: "#ffffff" }}
            // onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDragStart={(_, node) => handleNodeDragStart(node)}
            onNodeDragStop={(_, node) => handleNodeDragStop(node)}
            onNodeDoubleClick={(_, node) => handleNodeDoubleClick(node)}
            onNodeMouseEnter={(_, node) => handleNodeMouseEnter(node)}
            onNodeMouseLeave={(_, node) => handleNodeMouseLeave(node)}
            // onNodeClick={(_, node) => handleNodeSingleClick(node)}
          >
            {/* <Background color="#CCCCCC" variant="lines" gap={80} size={0.8} /> */}
            {/* <Background variant="lines" gap={5} size={0.5} /> */}
            <MiniMap
              showInteractive={true}
              nodeStrokeColor={"#e71324"}
              nodeColor={"#f2f9f2"}
              nodeBorderRadius={3}
              style={{ right: 0, bottom: 0, height: 100, width: 140 }}
            />

            {/* <StyledControls showFitView={false} showInteractive={true}>
            <ControlButton onClick={() => onLayout("LR")}>
              <Share style={{ transform: "rotate(90deg)" }} />
            </ControlButton>
            <ControlButton onClick={() => onLayout("TB")}>
              <Share />
            </ControlButton>
          </StyledControls> */}
          </ReactFlow>
        </ReactFlowProvider>
      </div>

      {/* Slide Panel */}
      <div
        ref={panelRef}
        className={`slide-panel ${sidePanelShow ? "open" : "closed"}`}
      >
        <Close
          style={{ fontSize: "16px" }}
          onClick={closePanel}
          className="close-button"
        />

        {sidePanelShow && <SlideInfoPanel detailsInfo={sidePanelData} />}
      </div>
    </div>
    // </Wrapper>
  )
}

const areEqual = (prevProps, nextProps) => {
  if (isEqual(prevProps.treeData, nextProps.treeData)) {
    return true
  }

  return false
}

export default memo(LineageTree, areEqual)
