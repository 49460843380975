import React from "react"
import { Box } from "@mui/material"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: `${index === 3 ? "0" : "24px"}` }}>{children}</Box>
      )}
    </div>
  )
}

export default CustomTabPanel
