import React, { useState } from "react"
import { format } from "date-fns"
import "../nodeStyles/slideInfoPanel.style.css"
import { Launch } from "@mui/icons-material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { truncateText } from "../../../../utils/functions"

const SlideInfoPanel = ({ detailsInfo }) => {
  function formatDate(dateString) {
    if (dateString) return format(new Date(dateString), "yyyy-MM-dd")
  }
  return (
    <>
      {detailsInfo && (
        <div className="dataset-info-card">
          <div className="dataset-card-top">
            <p className="dataset-title">{detailsInfo?.name}</p>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip">
                  <span>FQN: {detailsInfo?.fqn}</span>
                </Tooltip>
              }
            >
              <div className="fqn-search-card-envelop">
                <p>{truncateText(detailsInfo?.fqn)}</p>
                {detailsInfo?.fqn && (
                  <ContentCopyIcon
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    color="info"
                    onClick={(event) => {
                      event.preventDefault()
                      window.navigator.clipboard.writeText(detailsInfo?.fqn)
                    }}
                  />
                )}
              </div>
            </OverlayTrigger>
          </div>
          <p className="dataset-description">
            Description: {detailsInfo?.description}
          </p>
          <div className="dataset-details">
            <div className="detail-row">
              <span className="detail-label">Type :</span>
              <span className="detail-value">{detailsInfo?.type || "NA"}</span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Organization :</span>
              <span className="detail-value">
                {detailsInfo?.orgModel?.map((org) => `${org}, `) || "NA"}
              </span>
            </div>
            <div className="detail-row">
              <span className="detail-label">lastModified :</span>
              <span className="detail-value">
                {formatDate(detailsInfo?.lastModified) || "NA"}
              </span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Platform :</span>
              <span className="detail-value">
                {detailsInfo?.platform?.map((item) => `${item}, `) || "NA"}
              </span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Product :</span>
              <span className="detail-value">
                {detailsInfo?.product || "NA"}
              </span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Metadata Source :</span>
              <span className="detail-value">
                {detailsInfo.metadataSource || "NA"}
              </span>
            </div>

            {/* Add more detail rows as needed */}
            {/* <button></button> */}
          </div>
          <div className="detail-row-uris">
            <a
              href={`https://metainsights.gleafink.com/DatasetDetails/${detailsInfo?.fqn}`}
              target="_blank"
              className="button-link"
              rel="noopener noreferrer"
            >
              <Launch fontSize="12px" /> Navigate to Resource
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default SlideInfoPanel
