import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { MARTBUILDER_API } from "../../serverConfig";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DeleteViewModal = ({ open, setOpen, token, toast, fetchViews }) => {
  const deleteviewdata = async (d) => {
    axios
      .delete(`${MARTBUILDER_API}/metainsightsview/${d.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res)
        toast.success("View Deleted Successfully!");
        if (res.status === 200) {
          fetchViews();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("View Deletion Failed!");
      });
    setOpen({});
  };

  return (
    <Modal
      open={Object.keys(open).length !== 0}
      onClose={() => setOpen({})}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Delete View
        </Typography>
        <div className="viewModalInputs">
          <Typography id="modal-modal-description">
            Are you sure you want to delete View: {open.name}?
          </Typography>
        </div>
        <Button
          className="addNewButton"
          variant="text"
          size="small"
          onClick={() => setOpen({})}
        >
          Cancel
        </Button>
        <Button
          className="addNewButton"
          variant="contained"
          size="small"
          onClick={() => deleteviewdata(open)}
        >
          Delete View
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteViewModal;
