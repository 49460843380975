const reducer = (draft, action) => {
  switch (action.type /*accessing type from action object */) {
    case "UPDATE_LINEAGE_DEPTH": {
      draft.lineageDepth = action.payload
      break
    }
    case "UPDATE_LINEAGE_TYPE": {
      draft.lineageView.columns = action.payload
      break
    }
    case "CLEAR_TREE_DATA": {
      draft.treeData.nodes = []
      draft.treeData.edges = []
      break
    }
    case "SET_SHOW_SIDE_PANEL": {
      draft.sidePanelShow = true
      break
    }
    case "SET_HIDE_SIDE_PANEL": {
      draft.sidePanelShow = false
      break
    }
    case "UPDATE_SIDE_PANEL_DATA": {
      draft.sidePanelData = action.payload
      break
    }
    case "CLEAN_SIDE_PANEL_DATA": {
      draft.sidePanelData = null
      break
    }
    case "SET_TREE_LINEAGE_DATA": {
      draft.treeData.nodes.push(
        ...action.payload.nodes.filter(
          (obj) => !draft.treeData.nodes.some((item) => item.id === obj.id)
        )
      )
      draft.treeData.edges.push(
        ...action.payload.edges.filter(
          (obj) => !draft.treeData.edges.some((item) => item.id === obj.id)
        )
      )
      break
    }
    default:
      throw new Error("No matched action!")
  }
}

export default reducer
