import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MARTBUILDER_API } from "../../serverConfig";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UpdateViewModal = ({
  open,
  setOpen,
  token,
  BUList,
  browseFetching,
  toast,
  fetchViews,
}) => {
  const [selectedBU, setSelectedBU] = useState([]);
  const [viewName, setViewName] = useState("");

  const updateview = async () => {
    let reqdata = "";
    reqdata = JSON.stringify({
      businessUnits: selectedBU,
      id: open.id,
      fqn: open.fqn,
      name: viewName,
    });
    axios
      .put(
        `${MARTBUILDER_API}/metainsightsview/updatemetainsightsview`,
        reqdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        toast.success("View Updated Successfully!");
        fetchViews();
      })
      .catch((error) => {
        console.log(error);
        toast.error("View Updation Failed!");
      });
    setSelectedBU([]);
    setViewName("");
    setOpen({});
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedBU(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (open) {
      setViewName(open.name);
      let bu = [];
      open.businessUnits?.map((item) => {
        bu.push(item);
      });
      setSelectedBU(bu);
    }
  }, [open.fqn]);

  return (
    <Modal
      open={Object.keys(open).length !== 0}
      onClose={() => setOpen({})}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Update View
        </Typography>
        {browseFetching ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "6rem",
            }}
          >
            <Skeleton variant="rounded" width={"70%"} height={50} />
            <Skeleton variant="rounded" width={"70%"} height={50} />
          </div>
        ) : (
          <>
            <div className="viewModalInputs">
              <Typography id="modal-modal-description">View Name: </Typography>
              <TextField
                id="outlined-basic"
                label="View Name"
                variant="outlined"
                size="small"
                sx={{ width: 400 }}
                value={viewName}
                onChange={(event) => {
                  setViewName(event.target.value);
                }}
              />
            </div>
            <div className="viewModalInputs">
              <Typography id="modal-modal-description">
                Business Units:
              </Typography>
              <FormControl sx={{ width: 400 }} size="small">
                <InputLabel id="demo-multiple-name-label">
                  Business Units
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={selectedBU}
                  onChange={handleChange}
                  input={<OutlinedInput label="Business Units" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {BUList?.map((bu) => (
                    <MenuItem key={bu.name} value={bu.name}>
                      {/* {bu.name} */}
                      <Checkbox checked={selectedBU?.includes(bu.name)} />
                      <ListItemText primary={bu.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              className="addNewButton"
              variant="text"
              size="small"
              onClick={() => setOpen({})}
            >
              Cancel
            </Button>
            <Button
              className="addNewButton"
              variant="contained"
              size="small"
              onClick={() => updateview()}
            >
              Update View
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UpdateViewModal;
