import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Typography,
  Select,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { FormControl, InputLabel, Checkbox, ListItemText } from "@mui/material";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import axios from "axios";
import { AuthContext } from "../../../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../../msalConfig";
import { DATABOOK_API } from "../../../serverConfig";
import { toast, ToastContainer } from "react-toastify";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const DataContract = ({ modaldata, getallcolumndata }) => {
  console.log(JSON.stringify(modaldata, null, 2), "  JSON format");
  const { datasetFQN } = useParams();
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  // Extract column names
  const [columnNames, setColumnNames] = useState([]);

  // Data Quality States
  const [dataQuality, setDataQuality] = useState({
    engine: "",
    operator: "",
    number: "",
    completenessValues: [],
    uniqueness: [],
  });

  // This array holds compliance rows (column + condition + value)
  const [complianceRows, setComplianceRows] = useState([
    { selectedColumn: "", selectedCondition: "", conditionValue: "" },
  ]);

  // Service Level States
  const [serviceLevel, setServiceLevel] = useState({
    availability: "",
    retentionValue: "",
    retentionUnit: "",
    latencyThresholdValue: "",
    latencyThresholdUnit: "",
    latencySourceTimestampField: "",
    latencyProcessedTimestampField: "",
    freshnessThresholdValue: "",
    freshnessThresholdUnit: "",
    freshnessTimestampField: "",
    frequencyType: "",
    frequencyCron: "",
    supportTime: "",
    supportResponseTime: "",
    supportResponseUnit: "",
    backupCron: "",
    backupRecoveryTime: "",
    backupRecoveryTimeUnit: "",
    backupRecoveryPoint: "",
    backupRecoveryPointUnit: "",
  });

  const [isEditingDataQuality, setIsEditingDataQuality] = useState(false);
  const [isEditingServiceLevel, setIsEditingServiceLevel] = useState(false);

  // ----------------------
  // 1) PARSE & PRE-POPULATE FROM PAYLOAD
  // ----------------------
  useEffect(() => {
    if (!modaldata) return;

    // 1a) Column names for the multi-select dropdowns
    if (modaldata?.columns) {
      const names = modaldata.columns.map((col) => col.name);
      setColumnNames(names);
    }

    // 1b) Data Quality: parse dataset-level expectations
    // e.g., for "hasSize"
    if (modaldata?.expectations) {
      // Look for the "hasSize" metric
      const hasSizeObj = modaldata.expectations.find(
        (exp) => exp.metric === "hasSize"
      );
      if (hasSizeObj) {
        setDataQuality((prev) => ({
          ...prev,
          // Default operator if none is provided
          operator: hasSizeObj.operation || "==",
          number: hasSizeObj.value || "",
        }));
      }
    }

    // 1c) Data Quality: parse column-level expectations
    // e.g., "isComplete" => completeness, "isUnique" => uniqueness
    //       "hasPattern"/"isContainedIn" => compliance rows
    const completenessArray = [];
    const uniquenessArray = [];
    const complianceArray = [];

    modaldata?.columns?.forEach((col) => {
      if (col?.expectations?.length) {
        col.expectations.forEach((exp) => {
          if (exp.metric === "isComplete") {
            completenessArray.push(col.name);
          } else if (exp.metric === "isUnique") {
            uniquenessArray.push(col.name);
          } else if (
            exp.metric === "hasPattern" ||
            exp.metric === "isContainedIn"
          ) {
            // Build a row for the compliance table
            complianceArray.push({
              selectedColumn: col.name,
              selectedCondition: exp.metric,
              conditionValue: exp.value || "",
            });
          }
        });
      }
    });

    setDataQuality((prev) => ({
      ...prev,
      completenessValues: completenessArray,
      uniqueness: uniquenessArray,
    }));

    // If we found any compliance expectations in columns, override the default single row
    if (complianceArray.length > 0) {
      setComplianceRows(complianceArray);
    }

    // 1d) Service Level fields
    // Availability
    if (modaldata?.availability?.percentage !== undefined) {
      setServiceLevel((prev) => ({
        ...prev,
        availability: String(modaldata.availability.percentage),
      }));
    }

    // Retention
    if (modaldata?.retention?.period !== undefined) {
      setServiceLevel((prev) => ({
        ...prev,
        retentionValue: String(modaldata.retention.period),
        retentionUnit: modaldata.retention.periodSuffix || "",
      }));
    }

    // Latency
    if (modaldata?.latency) {
      setServiceLevel((prev) => ({
        ...prev,
        latencyThresholdValue: String(modaldata.latency.threshold ?? ""),
        latencyThresholdUnit: modaldata.latency.thresholdSuffix || "",
        latencySourceTimestampField:
          modaldata.latency.sourceTimestampField || "",
        latencyProcessedTimestampField:
          modaldata.latency.processedTimestampField || "",
      }));
    }

    // Freshness
    if (modaldata?.freshness) {
      setServiceLevel((prev) => ({
        ...prev,
        freshnessThresholdValue: String(modaldata.freshness.threshold ?? ""),
        freshnessThresholdUnit: modaldata.freshness.thresholdSuffix || "",
        freshnessTimestampField: modaldata.freshness.timestampField || "",
      }));
    }

    // Frequency
    if (modaldata?.frequency) {
      setServiceLevel((prev) => ({
        ...prev,
        frequencyType: modaldata.frequency.type || "",
        frequencyCron: modaldata.frequency.cron || "",
      }));
    }

    // Support
    if (modaldata?.support) {
      setServiceLevel((prev) => ({
        ...prev,
        supportTime: modaldata.support.time || "",
        supportResponseTime: String(modaldata.support.responseTime ?? ""),
        supportResponseUnit: modaldata.support.responseTimeSuffix || "",
      }));
    }

    // Backup
    if (modaldata?.backup) {
      setServiceLevel((prev) => ({
        ...prev,
        backupCron: modaldata.backup.cron || "",
        backupRecoveryTime: String(modaldata.backup.recoveryTime ?? ""),
        backupRecoveryTimeUnit: modaldata.backup.recoveryTimeSuffix || "",
        backupRecoveryPoint: String(modaldata.backup.recoveryPoint ?? ""),
        backupRecoveryPointUnit: modaldata.backup.recoveryPointSuffix || "",
      }));
    }
  }, [modaldata]);

  // ----------------------
  // EVENT HANDLERS
  // ----------------------

  const handleDataQualityUniquenessChange = (event) => {
    const { value } = event.target;
    setDataQuality((prev) => ({
      ...prev,
      uniqueness: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleDataQualityCompletenessChange = (event) => {
    const {
      target: { value },
    } = event;
    setDataQuality((prev) => ({
      ...prev,
      completenessValues: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleDataQualityChange = (event) => {
    const { name, value } = event.target;
    setDataQuality((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const handleAddRow = () => {
    if (isEditingDataQuality) {
      setComplianceRows((prev) => [
        ...prev,
        { selectedColumn: "", selectedCondition: "", conditionValue: "" },
      ]);
    }
  };

  const handleRemoveRow = (index) => {
    if (isEditingDataQuality) {
      setComplianceRows((prev) => {
        const updatedRows = prev.filter((_, i) => i !== index);
        return updatedRows;
      });
    }
  };

  const handleRowChange = (index, event) => {
    const { name, value } = event.target;
    setComplianceRows((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const toggleDataQualityEditMode = () => {
    setIsEditingDataQuality(!isEditingDataQuality);
  };

  const toggleServiceLevelEditMode = () => {
    setIsEditingServiceLevel(!isEditingServiceLevel);
  };

  // ----------------------
  // SAVE / PUT to API
  // ----------------------
  // Just an example of how you are saving data

  const handleSaveDataQuality1 = async () => {
    const payload = {
      expectations: [
        {
          metric: "hasSize",
          operator: dataQuality.operator,
          value: dataQuality.number,
        },
      ],
      id: datasetFQN, // Using the ID from the URL
    };
    console.log("Final Payload for hasSize:", JSON.stringify(payload, null, 2));
    try {
      const response = await axios.put(`${DATABOOK_API}/datasets`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, // If token is needed
        },
      });
      console.log("PUT Response for hasSize:", response.data);

      if (response?.data) {
        toast.success("Data quality updated successfully!");
      }
      handleSaveDataQuality();
    } catch (error) {
      console.error("Error saving data quality hasSize:", error);
    }
  };

  // const handleSaveDataQuality = async () => {
  //   // Initialize payload structure
  //   const payload = {
  //     columns: [],
  //     id: datasetFQN,
  //   };

  //   // We'll keep track of columns that have expectations added.
  //   const columnExpectationsMap = {};

  //   // Add completeness expectations
  //   dataQuality.completenessValues.forEach((colName) => {
  //     if (!columnExpectationsMap[colName]) {
  //       columnExpectationsMap[colName] = [];
  //     }
  //     columnExpectationsMap[colName].push({ metric: "isComplete" });
  //   });

  //   // Add uniqueness expectations
  //   dataQuality.uniqueness.forEach((colName) => {
  //     if (!columnExpectationsMap[colName]) {
  //       columnExpectationsMap[colName] = [];
  //     }
  //     columnExpectationsMap[colName].push({ metric: "isUnique" });
  //   });

  //   // Add compliance expectations
  //   complianceRows.forEach(
  //     ({ selectedColumn, selectedCondition, conditionValue }) => {
  //       if (selectedColumn && selectedCondition) {
  //         if (!columnExpectationsMap[selectedColumn]) {
  //           columnExpectationsMap[selectedColumn] = [];
  //         }
  //         const expectation = { metric: selectedCondition };
  //         if (conditionValue) {
  //           expectation.value = conditionValue;
  //         }
  //         columnExpectationsMap[selectedColumn].push(expectation);
  //       }
  //     }
  //   );

  //   // Now build the final payload columns only for those that actually have expectations
  //   for (const [colName, expectations] of Object.entries(
  //     columnExpectationsMap
  //   )) {
  //     if (expectations.length > 0) {
  //       payload.columns.push({
  //         name: colName,
  //         expectations: expectations,
  //       });
  //     }
  //   }

  //   try {
  //     const response = await axios.put(
  //       `https://metainsights-api.gleafink.com:443/metainsights/datasets/dataset-columns`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`, // If token is needed
  //         },
  //       }
  //     );
  //     console.log("PUT Response for dataset-columns:", response.data);
  //   } catch (error) {
  //     console.error("Error saving data quality columns:", error);
  //   }

  //   console.log("Final Payload for columns:", JSON.stringify(payload, null, 2));
  // };

  const handleSaveDataQuality = async () => {
    // Initialize payload structure
    const payload = {
      columns: [],
      id: datasetFQN,
    };

    // We'll keep track of columns that have expectations added.
    const columnExpectationsMap = {};

    // Add completeness expectations
    dataQuality.completenessValues.forEach((colName) => {
      if (!columnExpectationsMap[colName]) {
        columnExpectationsMap[colName] = [];
      }
      columnExpectationsMap[colName].push({ metric: "isComplete" });
    });

    // Add uniqueness expectations
    dataQuality.uniqueness.forEach((colName) => {
      if (!columnExpectationsMap[colName]) {
        columnExpectationsMap[colName] = [];
      }
      columnExpectationsMap[colName].push({ metric: "isUnique" });
    });
  
    // Reflect updated compliance rows
    complianceRows.forEach(
      ({ selectedColumn, selectedCondition, conditionValue }) => {
        if (selectedColumn && selectedCondition) {
          if (!columnExpectationsMap[selectedColumn]) {
            columnExpectationsMap[selectedColumn] = [];
          }
          const expectation = { metric: selectedCondition };
          if (conditionValue) {
            expectation.value = conditionValue;
          }
          columnExpectationsMap[selectedColumn].push(expectation);
        }
      }
    );

    // Now build the final payload columns only for those that actually have expectations
    for (const [colName, expectations] of Object.entries(
      columnExpectationsMap
    )) {
      if (expectations.length > 0) {
        payload.columns.push({
          name: colName,
          expectations: expectations,
        });
      }
    }

    try {
      const response = await axios.put(
        `${DATABOOK_API}/datasets/dataset-columns`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("PUT Response for dataset-columns:", response.data);
      toast.success("Compliance rows updated successfully!");
  
      // Refresh data after update
      if (getallcolumndata) {
        setTimeout(() => {
          getallcolumndata(token);
        }, 3000);
      }
    } catch (error) {
      console.error("Error saving data quality columns:", error);
      toast.error("Failed to update compliance rows.");
    }
  };

  const callDataQualityApi = async () => {
    try {
      // 1) Save dataset-level "hasSize"
      await handleSaveDataQuality1();
      // 2) Then handle column-level completeness, uniqueness, compliance
      //    (this is called inside handleSaveDataQuality1)
      if (getallcolumndata) {
        setTimeout(() => {
          getallcolumndata(token);
        }, 3000); // 3-second delay
      }
    } catch (error) {
      console.error("Error during Data Quality API calls:", error);
    }
  };

  const handleSaveServiceLevel = async () => {
    const payload = {
      id: datasetFQN,
      availability: {
        description: "The server is available during support hours",
        percentage: Number(serviceLevel.availability),
      },
      retention: {
        description: "Data is retained for one year",
        period: Number(serviceLevel.retentionValue),
        periodSuffix: serviceLevel.retentionUnit || "year",
        unlimited: false,
      },
      latency: {
        description:
          "Data is available within threshold after the order was placed",
        threshold: Number(serviceLevel.latencyThresholdValue),
        thresholdSuffix: serviceLevel.latencyThresholdUnit || "hour",
        sourceTimestampField: serviceLevel.latencySourceTimestampField,
        processedTimestampField: serviceLevel.latencyProcessedTimestampField,
      },
      freshness: {
        description: "The age of the youngest row in a table.",
        threshold: Number(serviceLevel.freshnessThresholdValue),
        thresholdSuffix: serviceLevel.freshnessThresholdUnit || "hour",
        timestampField: serviceLevel.freshnessTimestampField,
      },
      frequency: {
        description: "Data is delivered once a day",
        type: serviceLevel.frequencyType,
        interval: "daily",
        cron: serviceLevel.frequencyCron,
      },
      support: {
        description:
          "The data is available during typical business hours at headquarters",
        time: serviceLevel.supportTime,
        responseTime: Number(serviceLevel.supportResponseTime),
        responseTimeSuffix: serviceLevel.supportResponseUnit || "hour",
      },
      backup: {
        description: "Data is backed up once a week, every Sunday at 0:00 UTC.",
        interval: "weekly",
        cron: serviceLevel.backupCron,
        recoveryTime: Number(serviceLevel.backupRecoveryTime),
        recoveryTimeSuffix: serviceLevel.backupRecoveryTimeUnit || "hour",
        recoveryPoint: serviceLevel.backupRecoveryPoint,
        recoveryPointSuffix: serviceLevel.backupRecoveryPointUnit || "week",
      },
    };
    console.log("Service Level Payload:", JSON.stringify(payload, null, 2));
    try {
      const response = await axios.put(
        "https://metainsights-api.gleafink.com/metainsights/datasets",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // If token is required
          },
        }
      );
      // console.log("Service Level PUT Response:", response.data);
      if (response.status === 200) {
        toast.success("Service Level updated successfully!");
      }
      if (getallcolumndata) {
        setTimeout(() => {
          getallcolumndata(token);
        }, 3000); // 3-second delay
      }
    } catch (error) {
      console.error("Error saving service level:", error);
    }
  };

  const handleResetDataQuality = () => {
    if (isEditingDataQuality) {
      getallcolumndata(token)
    }
  };
  
  // 2) Reset Service Level
  const handleResetServiceLevel = () => {
    if (isEditingServiceLevel) {
      getallcolumndata(token)
    }
  };

  const handleClearDataQuality = () => {
    if (isEditingDataQuality) {
    setDataQuality({
      engine: "",
      operator: "",
      number: "",
      completenessValues: [],
      uniqueness: [],
    });
  
    setComplianceRows([
      { selectedColumn: "", selectedCondition: "", conditionValue: "" },
    ]);
  }
  };
  
  const handleClearServiceLevel = () => {
    if (isEditingServiceLevel) {
    setServiceLevel({
      availability: "",
      retentionValue: "",
      retentionUnit: "",
      latencyThresholdValue: "",
      latencyThresholdUnit: "",
      latencySourceTimestampField: "",
      latencyProcessedTimestampField: "",
      freshnessThresholdValue: "",
      freshnessThresholdUnit: "",
      freshnessTimestampField: "",
      frequencyType: "",
      frequencyCron: "",
      supportTime: "",
      supportResponseTime: "",
      supportResponseUnit: "",
      backupCron: "",
      backupRecoveryTime: "",
      backupRecoveryTimeUnit: "",
      backupRecoveryPoint: "",
      backupRecoveryPointUnit: "",
    });
  } 
  };
  
 
  return (
    <>
      {/* ----------------------- DATA QUALITY RULES -------------------------------- */}
      <Box
        display="grid"
        gridTemplateColumns="55fr 45fr"
        gap={2}
        p={4}
        style={{ border: "1px solid #ddd", borderRadius: "8px" }}
      >
        {/* Left Side - Data Quality Rules */}
        <Box pr={2} style={{ borderRight: "1px solid #ddd" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor: "#fafafa",
            }}
          >
            Data Quality Rules
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column" gap={2}>
              {/* Engine */}
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Engine
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingDataQuality}
                >
                  <InputLabel>Engine</InputLabel>
                  <Select
                    label="Engine"
                    name="engine"
                    value={dataQuality.engine}
                    onChange={handleDataQualityChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="deequ">deequ</MenuItem>
                    <MenuItem value="sodacl">sodacl</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* Operator + number ( hasSize ) */}
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Size
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingDataQuality}
                >
                  <InputLabel>Operator</InputLabel>
                  <Select
                    label="Operator"
                    name="operator"
                    value={dataQuality.operator}
                    onChange={handleDataQualityChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value=">">{">"}</MenuItem>
                    <MenuItem value=">=">{">="}</MenuItem>
                    <MenuItem value="<">{"<"}</MenuItem>
                    <MenuItem value="<=">{"<="}</MenuItem>
                    <MenuItem value="==">{"=="}</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  type="number"
                  size="small"
                  fullWidth
                  disabled={!isEditingDataQuality}
                  name="number"
                  value={dataQuality.number}
                  onChange={handleDataQualityChange}
                />
              </Box>

              {/* Completeness */}
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Completeness
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingDataQuality}
                >
                  <InputLabel>Columns</InputLabel>
                  <Select
                    multiple
                    label="Columns"
                    name="completenessValues"
                    value={dataQuality.completenessValues}
                    onChange={handleDataQualityCompletenessChange}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {columnNames.map((option) => (
                      <MenuItem key={option} value={option}>
                        <Checkbox
                          checked={
                            dataQuality.completenessValues.indexOf(option) > -1
                          }
                        />
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Uniqueness */}
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Uniqueness
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingDataQuality}
                >
                  <InputLabel>Columns</InputLabel>
                  <Select
                    multiple
                    label="Columns"
                    name="uniqueness"
                    value={dataQuality.uniqueness}
                    onChange={handleDataQualityUniquenessChange}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {columnNames.map((option) => (
                      <MenuItem key={option} value={option}>
                        <Checkbox
                          checked={dataQuality.uniqueness.indexOf(option) > -1}
                        />
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Compliance Rows */}
              {complianceRows.map((row, index) => (
                <Box display="flex" alignItems="center" gap={2} key={index}>
                  {/* For the first row, label is "Compliance" */}
                  {index === 0 && (
                    <Typography
                      variant="body2"
                      style={{ minWidth: "120px", textAlign: "right" }}
                    >
                      Compliance
                    </Typography>
                  )}
                  {index > 0 && (
                    <Typography
                      variant="body2"
                      style={{ minWidth: "120px", textAlign: "right" }}
                    ></Typography>
                  )}

                  {/* Column selection */}
                  <FormControl
                    size="small"
                    fullWidth
                    disabled={!isEditingDataQuality}
                  >
                    <InputLabel>Column</InputLabel>
                    <Select
                      label="Column"
                      name="selectedColumn"
                      value={row.selectedColumn}
                      onChange={(e) => handleRowChange(index, e)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {columnNames.map((col) => (
                        <MenuItem key={col} value={col}>
                          {col}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Condition */}
                  <FormControl
                    size="small"
                    fullWidth
                    disabled={!isEditingDataQuality}
                  >
                    <InputLabel>Condition</InputLabel>
                    <Select
                      label="Condition"
                      name="selectedCondition"
                      value={row.selectedCondition}
                      onChange={(e) => handleRowChange(index, e)}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="isContainedIn">isContainedIn</MenuItem>
                      <MenuItem value="hasPattern">hasPattern</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Condition Value */}
                  <TextField
                    type="text"
                    size="small"
                    fullWidth
                    disabled={!isEditingDataQuality}
                    name="conditionValue"
                    value={row.conditionValue}
                    onChange={(e) => handleRowChange(index, e)}
                  />

                  {/* Add Row Button */}
                  <ControlPointRoundedIcon
                    onClick={handleAddRow}
                    style={{ cursor: "pointer" }}
                  />
                  <RemoveCircleOutlineIcon
                    onClick={() => handleRemoveRow(index)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          {/* Reset and Save Buttons */}
          <Box display="flex" gap={2} marginTop={3}>
          <Button variant="outlined" fullWidth  onClick={handleClearDataQuality} >
          Clear 
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleResetDataQuality}
            >
              Revert
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                if (isEditingDataQuality) {
                  callDataQualityApi();
                }
                toggleDataQualityEditMode();
              }}
            >
              {isEditingDataQuality ? "Save" : "Edit"}
            </Button>
          </Box>
        </Box>

        {/* Right Side - Data Quality Definitions */}
        <Box style={{ width: "100%" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor: "#fafafa",
            }}
          >
            Data Quality Definitions
          </Typography>
          <Box style={{ width: "100%" }}>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                width: "100%",
                whiteSpace: "normal",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <strong>Size:</strong> Minimum no. of expected for each batch of
              data processed
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                width: "100%",
                whiteSpace: "normal",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <strong>Completeness:</strong> Column values are expected to not
              be null or empty. Multi-select drop down
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                width: "100%",
                whiteSpace: "normal",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <strong>Uniqueness:</strong> Column values are expected to be
              unique. Multi-select drop down
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                width: "100%",
                whiteSpace: "normal",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <strong>Compliance:</strong> Column values are expected to be
              compliant with the values provided. Ex: within a range, within
              enum, etc.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* ----------------------- SERVICE LEVEL AGREEMENT --------------------------- */}
      <Box
        display="grid"
        gridTemplateColumns="55fr 45fr"
        gap={2}
        p={4}
        style={{ border: "1px solid #ddd", borderRadius: "8px" }}
      >
        {/* Left Side - Service Level Agreement */}
        <Box pr={2} style={{ borderRight: "1px solid #ddd" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor: "#fafafa",
            }}
          >
            Service Level Agreement
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column" gap={2}>
              {/* Availability */}
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Availability
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="availability"
                  value={serviceLevel.availability}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </Box>

              {/* Retention */}
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Retention
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="retentionValue"
                  value={serviceLevel.retentionValue}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <Select
                    name="retentionUnit"
                    value={serviceLevel.retentionUnit}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="years">Years</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* Latency */}
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Latency
              </Typography>

              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Threshold
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="latencyThresholdValue"
                  value={serviceLevel.latencyThresholdValue}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <Select
                    name="latencyThresholdUnit"
                    value={serviceLevel.latencyThresholdUnit}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="years">Years</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Source Timestamp
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <InputLabel>Columns</InputLabel>
                  <Select
                    label="Columns"
                    name="latencySourceTimestampField"
                    value={serviceLevel.latencySourceTimestampField}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {columnNames.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Processed Timestamp
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <InputLabel>Columns</InputLabel>
                  <Select
                    label="Columns"
                    name="latencyProcessedTimestampField"
                    value={serviceLevel.latencyProcessedTimestampField}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {columnNames.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Freshness */}
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Freshness
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Threshold
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="freshnessThresholdValue"
                  value={serviceLevel.freshnessThresholdValue}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <Select
                    name="freshnessThresholdUnit"
                    value={serviceLevel.freshnessThresholdUnit}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="years">Years</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Timestamp Field
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <InputLabel>Columns</InputLabel>
                  <Select
                    label="Columns"
                    name="freshnessTimestampField"
                    value={serviceLevel.freshnessTimestampField}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {columnNames.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Frequency */}
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Frequency
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Type
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <InputLabel>Frequency</InputLabel>
                  <Select
                    label="Frequency"
                    name="frequencyType"
                    value={serviceLevel.frequencyType}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="batch">batch</MenuItem>
                    <MenuItem value="streaming">streaming</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  cron (for batch)
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="frequencyCron"
                  value={serviceLevel.frequencyCron}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </Box>

              {/* Support */}
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Support
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Time
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="supportTime"
                  value={serviceLevel.supportTime}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </Box>

              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Response Time
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="supportResponseTime"
                  value={serviceLevel.supportResponseTime}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <Select
                    name="supportResponseUnit"
                    value={serviceLevel.supportResponseUnit}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="years">Years</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* Backup */}
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Backup
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Cron
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="backupCron"
                  value={serviceLevel.backupCron}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </Box>

              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Recovery Time
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="backupRecoveryTime"
                  value={serviceLevel.backupRecoveryTime}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <Select
                    name="backupRecoveryTimeUnit"
                    value={serviceLevel.backupRecoveryTimeUnit}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="years">Years</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="body2"
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  Recovery Point
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                  name="backupRecoveryPoint"
                  value={serviceLevel.backupRecoveryPoint}
                  onChange={(e) => {
                    setServiceLevel((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                <FormControl
                  size="small"
                  fullWidth
                  disabled={!isEditingServiceLevel}
                >
                  <Select
                    name="backupRecoveryPointUnit"
                    value={serviceLevel.backupRecoveryPointUnit}
                    onChange={(e) => {
                      setServiceLevel((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="years">Years</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>

          {/* SLA Reset and Save */}
          <Box display="flex" gap={2} marginTop={3}>
            <Button variant="outlined" fullWidth  onClick={handleClearServiceLevel}>
              Clear
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleResetServiceLevel}
            >
              Revert
            </Button>

            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                if (isEditingServiceLevel) {
                  handleSaveServiceLevel();
                }
                toggleServiceLevelEditMode();
              }}
            >
              {isEditingServiceLevel ? "Save" : "Edit"}
            </Button>
          </Box>
        </Box>

        {/* Right Side - Service Level Definitions */}
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              marginBottom: "20px",
              backgroundColor: "#fafafa",
            }}
          >
            Service Level Definitions
          </Typography>
          <Box sx={{ textAlign: "left" }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "10px" }}
            >
              <strong>Availability:</strong> The server is available during
              support hours
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "10px" }}
            >
              <strong>Retention:</strong> Data is retained for one year
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "10px" }}
            >
              <strong>Latency:</strong> Time it takes for data to be available
              after it is generated in the source. Ex: For a daily batch job
              that runs at 1AM every day for previous day's data, maximum time
              it takes is 25(hr) + job execution time (1hr).
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "10px" }}
            >
              <strong>Freshness:</strong> The age of the youngest row in a
              table.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "10px" }}
            >
              <strong>Frequency:</strong> Data is delivered once a day
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "10px" }}
            >
              <strong>Support:</strong> The data is available during typical
              business hours at headquarters
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "10px" }}
            >
              <strong>Backup:</strong> Data is backed up once a week, every
              Sunday at 0:00 UTC.
            </Typography>
          </Box>
        </Box>
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={1300}
        newestOnTop
        closeOnClick
        draggable
        pauseOnHover
        style={{ width: "450px", height: "250px" }}
      />
    </>
  );
};

export default DataContract;
